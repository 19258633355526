import Axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import FrwkResponse from '../../types/FrwkResponse';
import {STATUS_CODES} from '../../scripts/constants';
import {setCookie} from 'typescript-cookie';
import {useSetupVars} from './useSetupVars';

type Resolve = (value: unknown) => void;
type Reject = (reason?: unknown) => void;
export type RequestMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type ValidResponse<U> = FrwkResponse<U> | AxiosResponse<U>;

type RequestObject = {
    requestType: RequestMethods,
    url: string,
    params?: Record<string, unknown> | FormData,
    additionalHeaders?: Record<string, unknown>,
    additionalRequestConfig?: Record<string, unknown>
}

export function useRawAxios() {

    const axiosActionExternal = (config: Record<string, unknown> | AxiosRequestConfig) => {
        return Axios(config)
            .then((response: AxiosResponse) => Promise.resolve(response))
            .catch((exception: unknown) => Promise.reject(exception));
    };

    function axiosActionInternal<ResponseType extends FrwkResponse<unknown> | AxiosResponse<unknown>>(requestObject: RequestObject, rawResponse = false): Promise<ResponseType> {
        const {
            requestType,
            url,
            params,
            additionalHeaders,
            additionalRequestConfig,
        } = requestObject;

        const readOrDelete = requestType === 'GET' || requestType === 'DELETE';
        const createOrUpdate = requestType === 'POST' || requestType === 'PUT' || requestType === 'PATCH';
        const addCsrfToken = requestType !== 'GET';

        const requestConfig: AxiosRequestConfig = {
            method: requestType,
            url: url,
            params: readOrDelete ? getGlobalFields(params, addCsrfToken) : null,
            data: createOrUpdate ? getGlobalFields(params, addCsrfToken) : null,
            headers: { ...getCommonHeaders(), ...additionalHeaders },
        };

        return new Promise((resolve, reject) => {
            axiosActionExternal({ ...requestConfig, ...additionalRequestConfig })
                .then((response: AxiosResponse) => {
                    setupClientEnv(response);
                    redirectIfRequired(response);
                    resolveIfOkay(response, resolve, rawResponse);
                })
                .catch((exception: { response: AxiosResponse }) => {
                    redirectIfRequired(exception.response);
                    rejectIfFailed(exception.response, reject, rawResponse);
                });
        });
    }

    function setupClientEnv(response: AxiosResponse) {
        const {csrftoken, jwt} = response.headers;
        if(jwt) {
            setCookie('jwt', jwt);
            const element = document.querySelector('meta[name="businessId"]');
            const businessId = element instanceof HTMLMetaElement ? element.content : '';
            if(businessId) {
                setCookie(businessId, jwt, { domain: import.meta.env.PROD ? '.helcim.app' : '.helcim.test', path: '/helcim-pay' });
            }
        }
        if(!csrftoken) { return; }

        const csrfMetaTag: HTMLMetaElement | null = document.querySelector('meta[name="csrfToken"]');
        csrfMetaTag.content = csrftoken;
    }

    return {
        axiosActionExternal,
        axiosActionInternal,
        setupClientEnv,
    };

}

function redirectIfRequired(response: AxiosResponse) {
    const redirectTo = response && response.headers ? response.headers['redirect-url'] : '';
    if(redirectTo && redirectTo !== window.location.href) {
        window.location.href = redirectTo;
    }
}

function resolveIfOkay(response: AxiosResponse, resolve: Resolve, rawResponse: boolean) {
    if(response.status === STATUS_CODES.OK_200) {
        const responseToReturn = rawResponse ? response : response.data;
        resolve(responseToReturn);
    }
}

function rejectIfFailed(response: AxiosResponse, reject: Reject, rawResponse: boolean) {
    if(response && response.status !== STATUS_CODES.OK_200) {
        const dataErrors = response.data.errors || {};
        const hasDataErrors = Object.keys(dataErrors).length > 0;
        const responseMessage = response.data.responseMessage ? response.data.responseMessage : response.data[0];
        const responseToReturn = rawResponse ? response : hasDataErrors ? dataErrors : responseMessage;
        reject(responseToReturn);
    }
}

function getGlobalFields (params: Record<string, unknown> | FormData, addCsrfToken: boolean) {
    if (params instanceof FormData) {
        params.append('preserveKeys', '0');
        if (addCsrfToken) {
            const csrfMetaTag: HTMLMetaElement | null = document.querySelector('meta[name="csrfToken"]');
            params.append('csrfToken', csrfMetaTag instanceof HTMLMetaElement ? csrfMetaTag.content : '');
        }
        return params;
    }
    params.preserveKeys = params.preserveKeys ?? false;
    if (addCsrfToken) {
        const csrfMetaTag: HTMLMetaElement | null = document.querySelector('meta[name="csrfToken"]');
        params = {
            ...params,
            ...{ 'csrfToken': csrfMetaTag instanceof HTMLMetaElement ? csrfMetaTag.content : '' },
        };
    }
    return params;
}

function getCommonHeaders() {
    const { jwt, csrfToken } = useSetupVars();
    return {
        'csrfToken': csrfToken.value,
        'jwt': jwt.value,
    };
}
