export const COUNTRIES = {
    CANADA: 'CA',
    UNITED_STATES: 'US',
    ANDORRA: 'AD',
    UNITED_ARAB_EMIRATES: 'AE',
    AFGHANISTAN: 'AF',
    ANTIGUA_AND_BARBUDA: 'AG',
    ANGUILLA: 'AI',
    ALBANIA: 'AL',
    ARMENIA: 'AM',
    ANGOLA: 'AO',
    ANTARCTICA: 'AQ',
    ARGENTINA: 'AR',
    AMERICAN_SAMOA: 'AS',
    AUSTRIA: 'AT',
    AUSTRALIA: 'AU',
    ARUBA: 'AW',
    ALAND_ISLANDS: 'AX',
    AZERBAIJAN: 'AZ',
    BOSNIA_AND_HERZEGOVINA: 'BA',
    BARBADOS: 'BB',
    BANGLADESH: 'BD',
    BELGIUM: 'BE',
    BURKINA_FASO: 'BF',
    BULGARIA: 'BG',
    BAHRAIN: 'BH',
    BURUNDI: 'BI',
    BENIN: 'BJ',
    SAINT_BARTHELEMY: 'BL',
    BERMUDA: 'BM',
    BRUNEI_DARUSSALAM: 'BN',
    BOLIVIA: 'BO',
    BONAIRE: 'BQ',
    BRAZIL: 'BR',
    BAHAMAS: 'BS',
    BHUTAN: 'BT',
    BOUVET_ISLAND: 'BV',
    BOTSWANA: 'BW',
    BELARUS: 'BY',
    BELIZE: 'BZ',
    COCOS_ISLANDS: 'CC',
    CONGO_DEMOCRATIC: 'CD',
    CENTRAL_AFRICAN_REPUBLIC: 'CF',
    CONGO: 'CG',
    SWITZERLAND: 'CH',
    COTE: 'CI',
    COOK_ISLANDS: 'CK',
    CHILE: 'CL',
    CAMEROON: 'CM',
    CHINA: 'CN',
    COLOMBIA: 'CO',
    COSTA_RICA: 'CR',
    CUBA: 'CU',
    CABO_VERDE: 'CV',
    CURACAO: 'CW',
    CHRISTMAS_ISLAND: 'CX',
    CYPRUS: 'CY',
    CZECH_REPUBLIC: 'CZ',
    GERMANY: 'DE',
    DJIBOUTI: 'DJ',
    DENMARK: 'DK',
    DOMINICA: 'DM',
    DOMINICAN_REPUBLIC: 'DO',
    ALGERIA: 'DZ',
    ECUADOR: 'EC',
    ESTONIA: 'EE',
    EGYPT: 'EG',
    WESTERN_SAHARA: 'EH',
    ERITREA: 'ER',
    SPAIN: 'ES',
    ETHIOPIA: 'ET',
    FINLAND: 'FI',
    FIJI: 'FJ',
    FALKLAND_ISLANDS: 'FK',
    MICRONESIA: 'FM',
    FAROE_ISLANDS: 'FO',
    FRANCE: 'FR',
    GABON: 'GA',
    UNITED_KINGDOM: 'GB',
    GRENADA: 'GD',
    GEORGIA: 'GE',
    FRENCH_GUIANA: 'GF',
    GUERNSEY: 'GG',
    GHANA: 'GH',
    GIBRALTAR: 'GI',
    GREENLAND: 'GL',
    GAMBIA: 'GM',
    GUINEA: 'GN',
    GUADELOUPE: 'GP',
    EQUATORIAL_GUINEA: 'GQ',
    GREECE: 'GR',
    SOUTH_GEORGIA: 'GS',
    GUATEMALA: 'GT',
    GUAM: 'GU',
    GUINEA_BISSAU: 'GW',
    GUYANA: 'GY',
    HONG_KONG: 'HK',
    HEARD_ISLAND_AND_MCDONALD_ISLANDS: 'HM',
    HONDURAS: 'HN',
    CROATIA: 'HR',
    HAITI: 'HT',
    HUNGARY: 'HU',
    INDONESIA: 'ID',
    IRELAND: 'IE',
    ISRAEL: 'IL',
    ISLE_OF_MAN: 'IM',
    INDIA: 'IN',
    BRITISH_INDIAN_OCEAN_TERRITORY: 'IO',
    IRAQ: 'IQ',
    IRAN: 'IR',
    ICELAND: 'IS',
    ITALY: 'IT',
    JERSEY: 'JE',
    JAMAICA: 'JM',
    JORDAN: 'JO',
    JAPAN: 'JP',
    KENYA: 'KE',
    KYRGYZSTAN: 'KG',
    CAMBODIA: 'KH',
    KIRIBATI: 'KI',
    COMOROS: 'KM',
    SAINT_KITTS_AND_NEVIS: 'KN',
    NORTH_KOREA: 'KP',
    KOREA: 'KR',
    KUWAIT: 'KW',
    CAYMAN_ISLANDS: 'KY',
    KAZAKHSTAN: 'KZ',
    LAO: 'LA',
    LEBANON: 'LB',
    SAINT_LUCIA: 'LC',
    LIECHTENSTEIN: 'LI',
    SRI_LANKA: 'LK',
    LIBERIA: 'LR',
    LESOTHO: 'LS',
    LITHUANIA: 'LT',
    LUXEMBOURG: 'LU',
    LATVIA: 'LV',
    LIBYA: 'LY',
    MOROCCO: 'MA',
    MONACO: 'MC',
    MOLDOVA: 'MD',
    MONTENEGRO: 'ME',
    SAINT_MARTIN: 'MF',
    MADAGASCAR: 'MG',
    MARSHALL_ISLANDS: 'MH',
    MACEDONIA: 'MK',
    MALI: 'ML',
    MYANMAR: 'MM',
    MONGOLIA: 'MN',
    MACAO: 'MO',
    NORTHERN_MARIANA_ISLANDS: 'MP',
    MARTINIQUE: 'MQ',
    MAURITANIA: 'MR',
    MONTSERRAT: 'MS',
    MALTA: 'MT',
    MAURITIUS: 'MU',
    MALDIVES: 'MV',
    MALAWI: 'MW',
    MEXICO: 'MX',
    MALAYSIA: 'MY',
    MOZAMBIQUE: 'MZ',
    NAMIBIA: 'NA',
    NEW_CALEDONIA: 'NC',
    NIGER: 'NE',
    NORFOLK_ISLAND: 'NF',
    NIGERIA: 'NG',
    NICARAGUA: 'NI',
    NETHERLANDS: 'NL',
    NORWAY: 'NO',
    NEPAL: 'NP',
    NAURU: 'NR',
    NIUE: 'NU',
    NEW_ZEALAND: 'NZ',
    OMAN: 'OM',
    PANAMA: 'PA',
    PERU: 'PE',
    FRENCH_POLYNESIA: 'PF',
    PAPUA_NEW_GUINEA: 'PG',
    PHILIPPINES: 'PH',
    PAKISTAN: 'PK',
    POLAND: 'PL',
    SAINT_PIERRE_AND_MIQUELON: 'PM',
    PITCAIRN: 'PN',
    PUERTO_RICO: 'PR',
    PALESTINE: 'PS',
    PORTUGAL: 'PT',
    PALAU: 'PW',
    PARAGUAY: 'PY',
    QATAR: 'QA',
    REUNION: 'RE',
    ROMANIA: 'RO',
    SERBIA: 'RS',
    RUSSIAN_FEDERATION: 'RU',
    RWANDA: 'RW',
    SAUDI_ARABIA: 'SA',
    SOLOMON_ISLANDS: 'SB',
    SEYCHELLES: 'SC',
    SUDAN: 'SD',
    SWEDEN: 'SE',
    SINGAPORE: 'SG',
    SAINT_HELENA: 'SH',
    SLOVENIA: 'SI',
    SVALBARD_AND_JAN_MAYEN: 'SJ',
    SLOVAKIA: 'SK',
    SIERRA_LEONE: 'SL',
    SAN_MARINO: 'SM',
    SENEGAL: 'SN',
    SOMALIA: 'SO',
    SURINAME: 'SR',
    SOUTH_SUDAN: 'SS',
    SAO_TOME_AND_PRINCIPE: 'ST',
    EL_SALVADOR: 'SV',
    SINT_MAARTEN: 'SX',
    SYRIAN_ARAB_REPUBLIC: 'SY',
    ESWATINI: 'SZ',
    TURKS_AND_CAICOS_ISLANDS: 'TC',
    CHAD: 'TD',
    FRENCH_SOUTHERN_TERRITORIES: 'TF',
    TOGO: 'TG',
    THAILAND: 'TH',
    TAJIKISTAN: 'TJ',
    TOKELAU: 'TK',
    TIMOR_LESTE: 'TL',
    TURKMENISTAN: 'TM',
    TUNISIA: 'TN',
    TONGA: 'TO',
    TURKEY: 'TR',
    TRINIDAD_AND_TOBAGO: 'TT',
    TUVALU: 'TV',
    TAIWAN: 'TW',
    TANZANIA: 'TZ',
    UKRAINE: 'UA',
    UGANDA: 'UG',
    UNITED_STATES_MINOR_OUTLYING_ISLANDS: 'UM',
    URUGUAY: 'UY',
    UZBEKISTAN: 'UZ',
    HOLY_SEE: 'VA',
    SAINT_VINCENT_AND_THE_GRENADINES: 'VC',
    VENEZUELA: 'VE',
    VIRGIN_ISLANDS_BRITISH: 'VG',
    VIRGIN_ISLANDS_US: 'VI',
    VIET_NAM: 'VN',
    VANUATU: 'VU',
    WALLIS_AND_FUTUNA: 'WF',
    SAMOA: 'WS',
    YEMEN: 'YE',
    MAYOTTE: 'YT',
    SOUTH_AFRICA: 'ZA',
    ZAMBIA: 'ZM',
    ZIMBABWE: 'ZW',
} as const;

export const COUNTRIES_ABBV = {
    [COUNTRIES.UNITED_STATES]: 'US',
    [COUNTRIES.CANADA]: 'CA',
    [COUNTRIES.ANDORRA]: 'AD',
    [COUNTRIES.UNITED_ARAB_EMIRATES]: 'AE',
    [COUNTRIES.AFGHANISTAN]: 'AF',
    [COUNTRIES.ANTIGUA_AND_BARBUDA]: 'AG',
    [COUNTRIES.ANGUILLA]: 'AI',
    [COUNTRIES.ALBANIA]: 'AL',
    [COUNTRIES.ARMENIA]: 'AM',
    [COUNTRIES.ANGOLA]: 'AO',
    [COUNTRIES.ANTARCTICA]: 'AQ',
    [COUNTRIES.ARGENTINA]: 'AR',
    [COUNTRIES.AMERICAN_SAMOA]: 'AS',
    [COUNTRIES.AUSTRIA]: 'AT',
    [COUNTRIES.AUSTRALIA]: 'AU',
    [COUNTRIES.ARUBA]: 'AW',
    [COUNTRIES.ALAND_ISLANDS]: 'AX',
    [COUNTRIES.AZERBAIJAN]: 'AZ',
    [COUNTRIES.BOSNIA_AND_HERZEGOVINA]: 'BA',
    [COUNTRIES.BARBADOS]: 'BB',
    [COUNTRIES.BANGLADESH]: 'BD',
    [COUNTRIES.BELGIUM]: 'BE',
    [COUNTRIES.BURKINA_FASO]: 'BF',
    [COUNTRIES.BULGARIA]: 'BG',
    [COUNTRIES.BAHRAIN]: 'BH',
    [COUNTRIES.BURUNDI]: 'BI',
    [COUNTRIES.BENIN]: 'BJ',
    [COUNTRIES.SAINT_BARTHELEMY]: 'BL',
    [COUNTRIES.BERMUDA]: 'BM',
    [COUNTRIES.BRUNEI_DARUSSALAM]: 'BN',
    [COUNTRIES.BOLIVIA]: 'BO',
    [COUNTRIES.BONAIRE]: 'BQ',
    [COUNTRIES.BRAZIL]: 'BR',
    [COUNTRIES.BAHAMAS]: 'BS',
    [COUNTRIES.BHUTAN]: 'BT',
    [COUNTRIES.BOUVET_ISLAND]: 'BV',
    [COUNTRIES.BOTSWANA]: 'BW',
    [COUNTRIES.BELARUS]: 'BY',
    [COUNTRIES.BELIZE]: 'BZ',
    [COUNTRIES.COCOS_ISLANDS]: 'CC',
    [COUNTRIES.CONGO_DEMOCRATIC]: 'CD',
    [COUNTRIES.CENTRAL_AFRICAN_REPUBLIC]: 'CF',
    [COUNTRIES.CONGO]: 'CG',
    [COUNTRIES.SWITZERLAND]: 'CH',
    [COUNTRIES.COTE]: 'CI',
    [COUNTRIES.COOK_ISLANDS]: 'CK',
    [COUNTRIES.CHILE]: 'CL',
    [COUNTRIES.CAMEROON]: 'CM',
    [COUNTRIES.CHINA]: 'CN',
    [COUNTRIES.COLOMBIA]: 'CO',
    [COUNTRIES.COSTA_RICA]: 'CR',
    [COUNTRIES.CUBA]: 'CU',
    [COUNTRIES.CABO_VERDE]: 'CV',
    [COUNTRIES.CURACAO]: 'CW',
    [COUNTRIES.CHRISTMAS_ISLAND]: 'CX',
    [COUNTRIES.CYPRUS]: 'CY',
    [COUNTRIES.CZECH_REPUBLIC]: 'CZ',
    [COUNTRIES.GERMANY]: 'DE',
    [COUNTRIES.DJIBOUTI]: 'DJ',
    [COUNTRIES.DENMARK]: 'DK',
    [COUNTRIES.DOMINICA]: 'DM',
    [COUNTRIES.DOMINICAN_REPUBLIC]: 'DO',
    [COUNTRIES.ALGERIA]: 'DZ',
    [COUNTRIES.ECUADOR]: 'EC',
    [COUNTRIES.ESTONIA]: 'EE',
    [COUNTRIES.EGYPT]: 'EG',
    [COUNTRIES.WESTERN_SAHARA]: 'EH',
    [COUNTRIES.ERITREA]: 'ER',
    [COUNTRIES.SPAIN]: 'ES',
    [COUNTRIES.ETHIOPIA]: 'ET',
    [COUNTRIES.FINLAND]: 'FI',
    [COUNTRIES.FIJI]: 'FJ',
    [COUNTRIES.FALKLAND_ISLANDS]: 'FK',
    [COUNTRIES.MICRONESIA]: 'FM',
    [COUNTRIES.FAROE_ISLANDS]: 'FO',
    [COUNTRIES.FRANCE]: 'FR',
    [COUNTRIES.GABON]: 'GA',
    [COUNTRIES.UNITED_KINGDOM]: 'GB',
    [COUNTRIES.GRENADA]: 'GD',
    [COUNTRIES.GEORGIA]: 'GE',
    [COUNTRIES.FRENCH_GUIANA]: 'GF',
    [COUNTRIES.GUERNSEY]: 'GG',
    [COUNTRIES.GHANA]: 'GH',
    [COUNTRIES.GIBRALTAR]: 'GI',
    [COUNTRIES.GREENLAND]: 'GL',
    [COUNTRIES.GAMBIA]: 'GM',
    [COUNTRIES.GUINEA]: 'GN',
    [COUNTRIES.GUADELOUPE]: 'GP',
    [COUNTRIES.EQUATORIAL_GUINEA]: 'GQ',
    [COUNTRIES.GREECE]: 'GR',
    [COUNTRIES.SOUTH_GEORGIA]: 'GS',
    [COUNTRIES.GUATEMALA]: 'GT',
    [COUNTRIES.GUAM]: 'GU',
    [COUNTRIES.GUINEA_BISSAU]: 'GW',
    [COUNTRIES.GUYANA]: 'GY',
    [COUNTRIES.HONG_KONG]: 'HK',
    [COUNTRIES.HEARD_ISLAND_AND_MCDONALD_ISLANDS]: 'HM',
    [COUNTRIES.HONDURAS]: 'HN',
    [COUNTRIES.CROATIA]: 'HR',
    [COUNTRIES.HAITI]: 'HT',
    [COUNTRIES.HUNGARY]: 'HU',
    [COUNTRIES.INDONESIA]: 'ID',
    [COUNTRIES.IRELAND]: 'IE',
    [COUNTRIES.ISRAEL]: 'IL',
    [COUNTRIES.ISLE_OF_MAN]: 'IM',
    [COUNTRIES.INDIA]: 'IN',
    [COUNTRIES.BRITISH_INDIAN_OCEAN_TERRITORY]: 'IO',
    [COUNTRIES.IRAQ]: 'IQ',
    [COUNTRIES.IRAN]: 'IR',
    [COUNTRIES.ICELAND]: 'IS',
    [COUNTRIES.ITALY]: 'IT',
    [COUNTRIES.JERSEY]: 'JE',
    [COUNTRIES.JAMAICA]: 'JM',
    [COUNTRIES.JORDAN]: 'JO',
    [COUNTRIES.JAPAN]: 'JP',
    [COUNTRIES.KENYA]: 'KE',
    [COUNTRIES.KYRGYZSTAN]: 'KG',
    [COUNTRIES.CAMBODIA]: 'KH',
    [COUNTRIES.KIRIBATI]: 'KI',
    [COUNTRIES.COMOROS]: 'KM',
    [COUNTRIES.SAINT_KITTS_AND_NEVIS]: 'KN',
    [COUNTRIES.NORTH_KOREA]: 'KP',
    [COUNTRIES.KOREA]: 'KR',
    [COUNTRIES.KUWAIT]: 'KW',
    [COUNTRIES.CAYMAN_ISLANDS]: 'KY',
    [COUNTRIES.KAZAKHSTAN]: 'KZ',
    [COUNTRIES.LAO]: 'LB',
    [COUNTRIES.SAINT_LUCIA]: 'LC',
    [COUNTRIES.LIECHTENSTEIN]: 'LI',
    [COUNTRIES.SRI_LANKA]: 'LK',
    [COUNTRIES.LIBERIA]: 'LR',
    [COUNTRIES.LESOTHO]: 'LS',
    [COUNTRIES.LITHUANIA]: 'LT',
    [COUNTRIES.LUXEMBOURG]: 'LU',
    [COUNTRIES.LATVIA]: 'LV',
    [COUNTRIES.LIBYA]: 'LY',
    [COUNTRIES.MOROCCO]: 'MA',
    [COUNTRIES.MONACO]: 'MC',
    [COUNTRIES.MOLDOVA]: 'MD',
    [COUNTRIES.MONTENEGRO]: 'ME',
    [COUNTRIES.SAINT_MARTIN]: 'MF',
    [COUNTRIES.MADAGASCAR]: 'MG',
    [COUNTRIES.MARSHALL_ISLANDS]: 'MH',
    [COUNTRIES.MACEDONIA]: 'MK',
    [COUNTRIES.MALI]: 'ML',
    [COUNTRIES.MYANMAR]: 'MM',
    [COUNTRIES.MONGOLIA]: 'MN',
    [COUNTRIES.MACAO]: 'MO',
    [COUNTRIES.NORTHERN_MARIANA_ISLANDS]: 'MP',
    [COUNTRIES.MARTINIQUE]: 'MQ',
    [COUNTRIES.MAURITANIA]: 'MR',
    [COUNTRIES.MONTSERRAT]: 'MS',
    [COUNTRIES.MALTA]: 'MT',
    [COUNTRIES.MAURITIUS]: 'MU',
    [COUNTRIES.MALDIVES]: 'MV',
    [COUNTRIES.MALAWI]: 'MW',
    [COUNTRIES.MEXICO]: 'MX',
    [COUNTRIES.MALAYSIA]: 'MY',
    [COUNTRIES.MOZAMBIQUE]: 'MZ',
    [COUNTRIES.NAMIBIA]: 'NA',
    [COUNTRIES.NEW_CALEDONIA]: 'NC',
    [COUNTRIES.NIGER]: 'NE',
    [COUNTRIES.NORFOLK_ISLAND]: 'NF',
    [COUNTRIES.NIGERIA]: 'NG',
    [COUNTRIES.NICARAGUA]: 'NI',
    [COUNTRIES.NETHERLANDS]: 'NL',
    [COUNTRIES.NORWAY]: 'NO',
    [COUNTRIES.NEPAL]: 'NP',
    [COUNTRIES.NAURU]: 'NR',
    [COUNTRIES.NIUE]: 'NU',
    [COUNTRIES.NEW_ZEALAND]: 'NZ',
    [COUNTRIES.OMAN]: 'OM',
    [COUNTRIES.PANAMA]: 'PA',
    [COUNTRIES.PERU]: 'PE',
    [COUNTRIES.FRENCH_POLYNESIA]: 'PF',
    [COUNTRIES.PAPUA_NEW_GUINEA]: 'PG',
    [COUNTRIES.PHILIPPINES]: 'PH',
    [COUNTRIES.PAKISTAN]: 'PK',
    [COUNTRIES.POLAND]: 'PL',
    [COUNTRIES.SAINT_PIERRE_AND_MIQUELON]: 'PM',
    [COUNTRIES.PITCAIRN]: 'PN',
    [COUNTRIES.PUERTO_RICO]: 'PR',
    [COUNTRIES.PALESTINE]: 'PS',
    [COUNTRIES.PORTUGAL]: 'PT',
    [COUNTRIES.PALAU]: 'PW',
    [COUNTRIES.PARAGUAY]: 'PY',
    [COUNTRIES.QATAR]: 'QA',
    [COUNTRIES.REUNION]: 'RE',
    [COUNTRIES.ROMANIA]: 'RO',
    [COUNTRIES.SERBIA]: 'RS',
    [COUNTRIES.RUSSIAN_FEDERATION]: 'RU',
    [COUNTRIES.RWANDA]: 'RW',
    [COUNTRIES.SAUDI_ARABIA]: 'SA',
    [COUNTRIES.SOLOMON_ISLANDS]: 'SB',
    [COUNTRIES.SEYCHELLES]: 'SC',
    [COUNTRIES.SUDAN]: 'SD',
    [COUNTRIES.SWEDEN]: 'SE',
    [COUNTRIES.SINGAPORE]: 'SG',
    [COUNTRIES.SAINT_HELENA]: 'SH',
    [COUNTRIES.SLOVENIA]: 'SI',
    [COUNTRIES.SVALBARD_AND_JAN_MAYEN]: 'SJ',
    [COUNTRIES.SLOVAKIA]: 'SK',
    [COUNTRIES.SIERRA_LEONE]: 'SL',
    [COUNTRIES.SAN_MARINO]: 'SM',
    [COUNTRIES.SENEGAL]: 'SN',
    [COUNTRIES.SOMALIA]: 'SO',
    [COUNTRIES.SURINAME]: 'SR',
    [COUNTRIES.SOUTH_SUDAN]: 'SS',
    [COUNTRIES.SAO_TOME_AND_PRINCIPE]: 'ST',
    [COUNTRIES.EL_SALVADOR]: 'SV',
    [COUNTRIES.SINT_MAARTEN]: 'SX',
    [COUNTRIES.SYRIAN_ARAB_REPUBLIC]: 'SY',
    [COUNTRIES.ESWATINI]: 'SZ',
    [COUNTRIES.TURKS_AND_CAICOS_ISLANDS]: 'TC',
    [COUNTRIES.CHAD]: 'TD',
    [COUNTRIES.FRENCH_SOUTHERN_TERRITORIES]: 'TF',
    [COUNTRIES.TOGO]: 'TG',
    [COUNTRIES.THAILAND]: 'TH',
    [COUNTRIES.TAJIKISTAN]: 'TJ',
    [COUNTRIES.TOKELAU]: 'TK',
    [COUNTRIES.TIMOR_LESTE]: 'TL',
    [COUNTRIES.TURKMENISTAN]: 'TM',
    [COUNTRIES.TUNISIA]: 'TN',
    [COUNTRIES.TONGA]: 'TO',
    [COUNTRIES.TURKEY]: 'TR',
    [COUNTRIES.TRINIDAD_AND_TOBAGO]: 'TT',
    [COUNTRIES.TUVALU]: 'TV',
    [COUNTRIES.TAIWAN]: 'TW',
    [COUNTRIES.TANZANIA]: 'TZ',
    [COUNTRIES.UKRAINE]: 'UA',
    [COUNTRIES.UGANDA]: 'UG',
    [COUNTRIES.UNITED_STATES_MINOR_OUTLYING_ISLANDS]: 'UM',
    [COUNTRIES.URUGUAY]: 'UY',
    [COUNTRIES.UZBEKISTAN]: 'UZ',
    [COUNTRIES.HOLY_SEE]: 'VA',
    [COUNTRIES.SAINT_VINCENT_AND_THE_GRENADINES]: 'VC',
    [COUNTRIES.VENEZUELA]: 'VE',
    [COUNTRIES.VIRGIN_ISLANDS_BRITISH]: 'VG',
    [COUNTRIES.VIRGIN_ISLANDS_US]: 'VI',
    [COUNTRIES.VIET_NAM]: 'VN',
    [COUNTRIES.VANUATU]: 'VU',
    [COUNTRIES.WALLIS_AND_FUTUNA]: 'WF',
    [COUNTRIES.SAMOA]: 'WS',
    [COUNTRIES.YEMEN]: 'YE',
    [COUNTRIES.MAYOTTE]: 'YT',
    [COUNTRIES.SOUTH_AFRICA]: 'ZA',
    [COUNTRIES.ZAMBIA]: 'ZM',
    [COUNTRIES.ZIMBABWE]: 'ZW',
};

export const COUNTRIES_NAMES_CA_US = {
    [COUNTRIES.CANADA]: 'Canada',
    [COUNTRIES.UNITED_STATES]: 'United States',
} as const;

export const COUNTRIES_NAMES = {
    [COUNTRIES.CANADA]: 'Canada',
    [COUNTRIES.UNITED_STATES]: 'United States',
    [COUNTRIES.ANDORRA]: 'Andorra',
    [COUNTRIES.UNITED_ARAB_EMIRATES]: 'United Arab Emirates',
    [COUNTRIES.AFGHANISTAN]: 'Afghanistan',
    [COUNTRIES.ANTIGUA_AND_BARBUDA]: 'Antigua and Barbuda',
    [COUNTRIES.ANGUILLA]: 'Anguilla',
    [COUNTRIES.ALBANIA]: 'Albania',
    [COUNTRIES.ARMENIA]: 'Armenia',
    [COUNTRIES.ANGOLA]: 'Angola',
    [COUNTRIES.ANTARCTICA]: 'Antarctica',
    [COUNTRIES.ARGENTINA]: 'Argentina',
    [COUNTRIES.AMERICAN_SAMOA]: 'American Samoa',
    [COUNTRIES.AUSTRIA]: 'Austria',
    [COUNTRIES.AUSTRALIA]: 'Australia',
    [COUNTRIES.ARUBA]: 'Aruba',
    [COUNTRIES.ALAND_ISLANDS]: 'Aland Islands',
    [COUNTRIES.AZERBAIJAN]: 'Azerbaijan',
    [COUNTRIES.BOSNIA_AND_HERZEGOVINA]: 'Bosnia and Herzegovina',
    [COUNTRIES.BARBADOS]: 'Barbados',
    [COUNTRIES.BANGLADESH]: 'Bangladesh',
    [COUNTRIES.BELGIUM]: 'Belgium',
    [COUNTRIES.BURKINA_FASO]: 'Burkina Faso',
    [COUNTRIES.BULGARIA]: 'Bulgaria',
    [COUNTRIES.BAHRAIN]: 'Bahrain',
    [COUNTRIES.BURUNDI]: 'Burundi',
    [COUNTRIES.BENIN]: 'Benin',
    [COUNTRIES.SAINT_BARTHELEMY]: 'Saint Barthelemy',
    [COUNTRIES.BERMUDA]: 'Bermuda',
    [COUNTRIES.BRUNEI_DARUSSALAM]: 'Brunei Darussalam',
    [COUNTRIES.BOLIVIA]: 'Bolivia, Plurinational State of',
    [COUNTRIES.BONAIRE]: 'Bonaire, Sint Eustatius and Saba',
    [COUNTRIES.BRAZIL]: 'Brazil',
    [COUNTRIES.BAHAMAS]: 'Bahamas',
    [COUNTRIES.BHUTAN]: 'Bhutan',
    [COUNTRIES.BOUVET_ISLAND]: 'Bouvet Island',
    [COUNTRIES.BOTSWANA]: 'Botswana',
    [COUNTRIES.BELARUS]: 'Belarus',
    [COUNTRIES.BELIZE]: 'Belize',
    [COUNTRIES.COCOS_ISLANDS]: 'Cocos (Keeling) Islands',
    [COUNTRIES.CONGO_DEMOCRATIC]: 'Congo, The Democratic Republic of The',
    [COUNTRIES.CENTRAL_AFRICAN_REPUBLIC]: 'Central African Republic',
    [COUNTRIES.CONGO]: 'Congo',
    [COUNTRIES.SWITZERLAND]: 'Switzerland',
    [COUNTRIES.COTE]: 'Cote D\'ivoire',
    [COUNTRIES.COOK_ISLANDS]: 'Cook Islands',
    [COUNTRIES.CHILE]: 'Chile',
    [COUNTRIES.CAMEROON]: 'Cameroon',
    [COUNTRIES.CHINA]: 'China',
    [COUNTRIES.COLOMBIA]: 'Colombia',
    [COUNTRIES.COSTA_RICA]: 'Costa Rica',
    [COUNTRIES.CUBA]: 'Cuba',
    [COUNTRIES.CABO_VERDE]: 'Cabo Verde',
    [COUNTRIES.CURACAO]: 'Curacao',
    [COUNTRIES.CHRISTMAS_ISLAND]: 'Christmas Island',
    [COUNTRIES.CYPRUS]: 'Cyprus',
    [COUNTRIES.CZECH_REPUBLIC]: 'Czech Republic',
    [COUNTRIES.GERMANY]: 'Germany',
    [COUNTRIES.DJIBOUTI]: 'Djibouti',
    [COUNTRIES.DENMARK]: 'Denmark',
    [COUNTRIES.DOMINICA]: 'Dominica',
    [COUNTRIES.DOMINICAN_REPUBLIC]: 'Dominican Republic',
    [COUNTRIES.ALGERIA]: 'Algeria',
    [COUNTRIES.ECUADOR]: 'Ecuador',
    [COUNTRIES.ESTONIA]: 'Estonia',
    [COUNTRIES.EGYPT]: 'Egypt',
    [COUNTRIES.WESTERN_SAHARA]: 'Western Sahara',
    [COUNTRIES.ERITREA]: 'Eritrea',
    [COUNTRIES.SPAIN]: 'Spain',
    [COUNTRIES.ETHIOPIA]: 'Ethiopia',
    [COUNTRIES.FINLAND]: 'Finland',
    [COUNTRIES.FIJI]: 'Fiji',
    [COUNTRIES.FALKLAND_ISLANDS]: 'Falkland Islands (Malvinas)',
    [COUNTRIES.MICRONESIA]: 'Micronesia, Federated States of',
    [COUNTRIES.FAROE_ISLANDS]: 'Faroe Islands',
    [COUNTRIES.FRANCE]: 'France',
    [COUNTRIES.GABON]: 'Gabon',
    [COUNTRIES.UNITED_KINGDOM]: 'United Kingdom',
    [COUNTRIES.GRENADA]: 'Grenada',
    [COUNTRIES.GEORGIA]: 'Georgia',
    [COUNTRIES.FRENCH_GUIANA]: 'French Guiana',
    [COUNTRIES.GUERNSEY]: 'Guernsey',
    [COUNTRIES.GHANA]: 'Ghana',
    [COUNTRIES.GIBRALTAR]: 'Gibraltar',
    [COUNTRIES.GREENLAND]: 'Greenland',
    [COUNTRIES.GAMBIA]: 'Gambia',
    [COUNTRIES.GUINEA]: 'Guinea',
    [COUNTRIES.GUADELOUPE]: 'Guadeloupe',
    [COUNTRIES.EQUATORIAL_GUINEA]: 'Equatorial Guinea',
    [COUNTRIES.GREECE]: 'Greece',
    [COUNTRIES.SOUTH_GEORGIA]: 'South Georgia and The South Sandwich Islands',
    [COUNTRIES.GUATEMALA]: 'Guatemala',
    [COUNTRIES.GUAM]: 'Guam',
    [COUNTRIES.GUINEA_BISSAU]: 'Guinea-Bissau',
    [COUNTRIES.GUYANA]: 'Guyana',
    [COUNTRIES.HONG_KONG]: 'Hong Kong',
    [COUNTRIES.HEARD_ISLAND_AND_MCDONALD_ISLANDS]: 'Heard Island and Mcdonald Islands',
    [COUNTRIES.HONDURAS]: 'Honduras',
    [COUNTRIES.CROATIA]: 'Croatia',
    [COUNTRIES.HAITI]: 'Haiti',
    [COUNTRIES.HUNGARY]: 'Hungary',
    [COUNTRIES.INDONESIA]: 'Indonesia',
    [COUNTRIES.IRELAND]: 'Ireland',
    [COUNTRIES.ISRAEL]: 'Israel',
    [COUNTRIES.ISLE_OF_MAN]: 'Isle of Man',
    [COUNTRIES.INDIA]: 'India',
    [COUNTRIES.BRITISH_INDIAN_OCEAN_TERRITORY]: 'British Indian Ocean Territory',
    [COUNTRIES.IRAQ]: 'Iraq',
    [COUNTRIES.IRAN]: 'Iran, Islamic Republic of',
    [COUNTRIES.ICELAND]: 'Iceland',
    [COUNTRIES.ITALY]: 'Italy',
    [COUNTRIES.JERSEY]: 'Jersey',
    [COUNTRIES.JAMAICA]: 'Jamaica',
    [COUNTRIES.JORDAN]: 'Jordan',
    [COUNTRIES.JAPAN]: 'Japan',
    [COUNTRIES.KENYA]: 'Kenya',
    [COUNTRIES.KYRGYZSTAN]: 'Kyrgyzstan',
    [COUNTRIES.CAMBODIA]: 'Cambodia',
    [COUNTRIES.KIRIBATI]: 'Kiribati',
    [COUNTRIES.COMOROS]: 'Comoros',
    [COUNTRIES.SAINT_KITTS_AND_NEVIS]: 'Saint Kitts and Nevis',
    [COUNTRIES.NORTH_KOREA]: 'Korea, Democratic People\'s Republic of',
    [COUNTRIES.KOREA]: 'Korea, Republic of',
    [COUNTRIES.KUWAIT]: 'Kuwait',
    [COUNTRIES.CAYMAN_ISLANDS]: 'Cayman Islands',
    [COUNTRIES.KAZAKHSTAN]: 'Kazakhstan',
    [COUNTRIES.LAO]: 'Lao People\'s Democratic Republic',
    [COUNTRIES.LEBANON]: 'Lebanon',
    [COUNTRIES.SAINT_LUCIA]: 'Saint Lucia',
    [COUNTRIES.LIECHTENSTEIN]: 'Liechtenstein',
    [COUNTRIES.SRI_LANKA]: 'Sri Lanka',
    [COUNTRIES.LIBERIA]: 'Liberia',
    [COUNTRIES.LESOTHO]: 'Lesotho',
    [COUNTRIES.LITHUANIA]: 'Lithuania',
    [COUNTRIES.LUXEMBOURG]: 'Luxembourg',
    [COUNTRIES.LATVIA]: 'Latvia',
    [COUNTRIES.LIBYA]: 'Libya',
    [COUNTRIES.MOROCCO]: 'Morocco',
    [COUNTRIES.MONACO]: 'Monaco',
    [COUNTRIES.MOLDOVA]: 'Moldova, Republic of',
    [COUNTRIES.MONTENEGRO]: 'Montenegro',
    [COUNTRIES.SAINT_MARTIN]: 'Saint Martin (French Part)',
    [COUNTRIES.MADAGASCAR]: 'Madagascar',
    [COUNTRIES.MARSHALL_ISLANDS]: 'Marshall Islands',
    [COUNTRIES.MACEDONIA]: 'Macedonia, The Former Yugoslav Republic of',
    [COUNTRIES.MALI]: 'Mali',
    [COUNTRIES.MYANMAR]: 'Myanmar',
    [COUNTRIES.MONGOLIA]: 'Mongolia',
    [COUNTRIES.MACAO]: 'Macao',
    [COUNTRIES.NORTHERN_MARIANA_ISLANDS]: 'Northern Mariana Islands',
    [COUNTRIES.MARTINIQUE]: 'Martinique',
    [COUNTRIES.MAURITANIA]: 'Mauritania',
    [COUNTRIES.MONTSERRAT]: 'Montserrat',
    [COUNTRIES.MALTA]: 'Malta',
    [COUNTRIES.MAURITIUS]: 'Mauritius',
    [COUNTRIES.MALDIVES]: 'Maldives',
    [COUNTRIES.MALAWI]: 'Malawi',
    [COUNTRIES.MEXICO]: 'Mexico',
    [COUNTRIES.MALAYSIA]: 'Malaysia',
    [COUNTRIES.MOZAMBIQUE]: 'Mozambique',
    [COUNTRIES.NAMIBIA]: 'Namibia',
    [COUNTRIES.NEW_CALEDONIA]: 'New Caledonia',
    [COUNTRIES.NIGER]: 'Niger',
    [COUNTRIES.NORFOLK_ISLAND]: 'Norfolk Island',
    [COUNTRIES.NIGERIA]: 'Nigeria',
    [COUNTRIES.NICARAGUA]: 'Nicaragua',
    [COUNTRIES.NETHERLANDS]: 'Netherlands',
    [COUNTRIES.NORWAY]: 'Norway',
    [COUNTRIES.NEPAL]: 'Nepal',
    [COUNTRIES.NAURU]: 'Nauru',
    [COUNTRIES.NIUE]: 'Niue',
    [COUNTRIES.NEW_ZEALAND]: 'New Zealand',
    [COUNTRIES.OMAN]: 'Oman',
    [COUNTRIES.PANAMA]: 'Panama',
    [COUNTRIES.PERU]: 'Peru',
    [COUNTRIES.FRENCH_POLYNESIA]: 'French Polynesia',
    [COUNTRIES.PAPUA_NEW_GUINEA]: 'Papua New Guinea',
    [COUNTRIES.PHILIPPINES]: 'Philippines',
    [COUNTRIES.PAKISTAN]: 'Pakistan',
    [COUNTRIES.POLAND]: 'Poland',
    [COUNTRIES.SAINT_PIERRE_AND_MIQUELON]: 'Saint Pierre and Miquelon',
    [COUNTRIES.PITCAIRN]: 'Pitcairn',
    [COUNTRIES.PUERTO_RICO]: 'Puerto Rico',
    [COUNTRIES.PALESTINE]: 'Palestine, State of',
    [COUNTRIES.PORTUGAL]: 'Portugal',
    [COUNTRIES.PALAU]: 'Palau',
    [COUNTRIES.PARAGUAY]: 'Paraguay',
    [COUNTRIES.QATAR]: 'Qatar',
    [COUNTRIES.REUNION]: 'Reunion',
    [COUNTRIES.ROMANIA]: 'Romania',
    [COUNTRIES.SERBIA]: 'Serbia',
    [COUNTRIES.RUSSIAN_FEDERATION]: 'Russian Federation',
    [COUNTRIES.RWANDA]: 'Rwanda',
    [COUNTRIES.SAUDI_ARABIA]: 'Saudi Arabia',
    [COUNTRIES.SOLOMON_ISLANDS]: 'Solomon Islands',
    [COUNTRIES.SEYCHELLES]: 'Seychelles',
    [COUNTRIES.SUDAN]: 'Sudan',
    [COUNTRIES.SWEDEN]: 'Sweden',
    [COUNTRIES.SINGAPORE]: 'Singapore',
    [COUNTRIES.SAINT_HELENA]: 'Saint Helena, Ascension and Tristan Da Cunha',
    [COUNTRIES.SLOVENIA]: 'Slovenia',
    [COUNTRIES.SVALBARD_AND_JAN_MAYEN]: 'Svalbard and Jan Mayen',
    [COUNTRIES.SLOVAKIA]: 'Slovakia',
    [COUNTRIES.SIERRA_LEONE]: 'Sierra Leone',
    [COUNTRIES.SAN_MARINO]: 'San Marino',
    [COUNTRIES.SENEGAL]: 'Senegal',
    [COUNTRIES.SOMALIA]: 'Somalia',
    [COUNTRIES.SURINAME]: 'Suriname',
    [COUNTRIES.SOUTH_SUDAN]: 'South Sudan',
    [COUNTRIES.SAO_TOME_AND_PRINCIPE]: 'Sao Tome and Principe',
    [COUNTRIES.EL_SALVADOR]: 'El Salvador',
    [COUNTRIES.SINT_MAARTEN]: 'Sint Maarten (Dutch Part)',
    [COUNTRIES.SYRIAN_ARAB_REPUBLIC]: 'Syrian Arab Republic',
    [COUNTRIES.ESWATINI]: 'Eswatini',
    [COUNTRIES.TURKS_AND_CAICOS_ISLANDS]: 'Turks and Caicos Islands',
    [COUNTRIES.CHAD]: 'Chad',
    [COUNTRIES.FRENCH_SOUTHERN_TERRITORIES]: 'French Southern Territories',
    [COUNTRIES.TOGO]: 'Togo',
    [COUNTRIES.THAILAND]: 'Thailand',
    [COUNTRIES.TAJIKISTAN]: 'Tajikistan',
    [COUNTRIES.TOKELAU]: 'Tokelau',
    [COUNTRIES.TIMOR_LESTE]: 'Timor-Leste',
    [COUNTRIES.TURKMENISTAN]: 'Turkmenistan',
    [COUNTRIES.TUNISIA]: 'Tunisia',
    [COUNTRIES.TONGA]: 'Tonga',
    [COUNTRIES.TURKEY]: 'Turkey',
    [COUNTRIES.TRINIDAD_AND_TOBAGO]: 'Trinidad and Tobago',
    [COUNTRIES.TUVALU]: 'Tuvalu',
    [COUNTRIES.TAIWAN]: 'Taiwan, Province of China',
    [COUNTRIES.TANZANIA]: 'Tanzania, United Republic of',
    [COUNTRIES.UKRAINE]: 'Ukraine',
    [COUNTRIES.UGANDA]: 'Uganda',
    [COUNTRIES.UNITED_STATES_MINOR_OUTLYING_ISLANDS]: 'United States Minor Outlying Islands',
    [COUNTRIES.URUGUAY]: 'Uruguay',
    [COUNTRIES.UZBEKISTAN]: 'Uzbekistan',
    [COUNTRIES.HOLY_SEE]: 'Holy See',
    [COUNTRIES.SAINT_VINCENT_AND_THE_GRENADINES]: 'Saint Vincent and The Grenadines',
    [COUNTRIES.VENEZUELA]: 'Venezuela, Bolivarian Republic of',
    [COUNTRIES.VIRGIN_ISLANDS_BRITISH]: 'Virgin Islands, British',
    [COUNTRIES.VIRGIN_ISLANDS_US]: 'Virgin Islands, U.S.',
    [COUNTRIES.VIET_NAM]: 'Viet Nam',
    [COUNTRIES.VANUATU]: 'Vanuatu',
    [COUNTRIES.WALLIS_AND_FUTUNA]: 'Wallis and Futuna',
    [COUNTRIES.SAMOA]: 'Samoa',
    [COUNTRIES.YEMEN]: 'Yemen',
    [COUNTRIES.MAYOTTE]: 'Mayotte',
    [COUNTRIES.SOUTH_AFRICA]: 'South Africa',
    [COUNTRIES.ZAMBIA]: 'Zambia',
    [COUNTRIES.ZIMBABWE]: 'Zimbabwe',
} as const;

export const COUNTRIES_AREA_CODES = {
    [COUNTRIES.ANDORRA]: '376',
    [COUNTRIES.UNITED_ARAB_EMIRATES]: '971',
    [COUNTRIES.AFGHANISTAN]: '93',
    [COUNTRIES.ANTIGUA_AND_BARBUDA]: '1-268',
    [COUNTRIES.ANGUILLA]: '1-264',
    [COUNTRIES.ALBANIA]: '355',
    [COUNTRIES.ARMENIA]: '374',
    [COUNTRIES.ANGOLA]: '244',
    [COUNTRIES.ANTARCTICA]: '672',
    [COUNTRIES.ARGENTINA]: '54',
    [COUNTRIES.AMERICAN_SAMOA]: '1-684',
    [COUNTRIES.AUSTRIA]: '43',
    [COUNTRIES.AUSTRALIA]: '61',
    [COUNTRIES.ARUBA]: '297',
    [COUNTRIES.ALAND_ISLANDS]: '358 457',
    [COUNTRIES.AZERBAIJAN]: '994',
    [COUNTRIES.BOSNIA_AND_HERZEGOVINA]: '387',
    [COUNTRIES.BARBADOS]: '1-246',
    [COUNTRIES.BANGLADESH]: '880',
    [COUNTRIES.BELGIUM]: '32',
    [COUNTRIES.BURKINA_FASO]: '226',
    [COUNTRIES.BULGARIA]: '359',
    [COUNTRIES.BAHRAIN]: '973',
    [COUNTRIES.BURUNDI]: '257',
    [COUNTRIES.BENIN]: '229',
    [COUNTRIES.SAINT_BARTHELEMY]: '590',
    [COUNTRIES.BERMUDA]: '1-441',
    [COUNTRIES.BRUNEI_DARUSSALAM]: '673',
    [COUNTRIES.BOLIVIA]: '591',
    [COUNTRIES.BRAZIL]: '55',
    [COUNTRIES.BAHAMAS]: '1-242',
    [COUNTRIES.BHUTAN]: '975',
    [COUNTRIES.BOUVET_ISLAND]: '55',
    [COUNTRIES.BOTSWANA]: '267',
    [COUNTRIES.BELARUS]: '375',
    [COUNTRIES.BELIZE]: '501',
    [COUNTRIES.CANADA]: '1',
    [COUNTRIES.COCOS_ISLANDS]: '61',
    [COUNTRIES.CONGO_DEMOCRATIC]: '243',
    [COUNTRIES.CENTRAL_AFRICAN_REPUBLIC]: '236',
    [COUNTRIES.CONGO]: '242',
    [COUNTRIES.SWITZERLAND]: '41',
    [COUNTRIES.COTE]: '225',
    [COUNTRIES.COOK_ISLANDS]: '682',
    [COUNTRIES.CHILE]: '56',
    [COUNTRIES.CAMEROON]: '237',
    [COUNTRIES.CHINA]: '86',
    [COUNTRIES.COLOMBIA]: '57',
    [COUNTRIES.COSTA_RICA]: '506',
    [COUNTRIES.CUBA]: '53',
    [COUNTRIES.CURACAO]: '599',
    [COUNTRIES.CHRISTMAS_ISLAND]: '61',
    [COUNTRIES.CYPRUS]: '357',
    [COUNTRIES.CZECH_REPUBLIC]: '420',
    [COUNTRIES.GERMANY]: '49',
    [COUNTRIES.DJIBOUTI]: '253',
    [COUNTRIES.DENMARK]: '45',
    [COUNTRIES.DOMINICA]: '1-767',
    [COUNTRIES.DOMINICAN_REPUBLIC]: '1',
    [COUNTRIES.ALGERIA]: '213',
    [COUNTRIES.ECUADOR]: '593',
    [COUNTRIES.ESTONIA]: '372',
    [COUNTRIES.EGYPT]: '20',
    [COUNTRIES.WESTERN_SAHARA]: '212',
    [COUNTRIES.ERITREA]: '291',
    [COUNTRIES.SPAIN]: '34',
    [COUNTRIES.ETHIOPIA]: '251',
    [COUNTRIES.FINLAND]: '358',
    [COUNTRIES.FIJI]: '679',
    [COUNTRIES.FALKLAND_ISLANDS]: '500',
    [COUNTRIES.MICRONESIA]: '691',
    [COUNTRIES.FAROE_ISLANDS]: '298',
    [COUNTRIES.FRANCE]: '33',
    [COUNTRIES.GABON]: '241',
    [COUNTRIES.UNITED_KINGDOM]: '44',
    [COUNTRIES.GRENADA]: '1-473',
    [COUNTRIES.GEORGIA]: '995',
    [COUNTRIES.FRENCH_GUIANA]: '594',
    [COUNTRIES.GUERNSEY]: '44-1481',
    [COUNTRIES.GHANA]: '233',
    [COUNTRIES.GIBRALTAR]: '350',
    [COUNTRIES.GREENLAND]: '299',
    [COUNTRIES.GAMBIA]: '220',
    [COUNTRIES.GUINEA]: '224',
    [COUNTRIES.GUADELOUPE]: '590',
    [COUNTRIES.EQUATORIAL_GUINEA]: '240',
    [COUNTRIES.GREECE]: '30',
    [COUNTRIES.SOUTH_GEORGIA]: '500',
    [COUNTRIES.GUATEMALA]: '502',
    [COUNTRIES.GUAM]: '1-671',
    [COUNTRIES.GUINEA_BISSAU]: '245',
    [COUNTRIES.GUYANA]: '592',
    [COUNTRIES.HONG_KONG]: '852',
    [COUNTRIES.HEARD_ISLAND_AND_MCDONALD_ISLANDS]: '672',
    [COUNTRIES.HONDURAS]: '504',
    [COUNTRIES.CROATIA]: '385',
    [COUNTRIES.HAITI]: '509',
    [COUNTRIES.HUNGARY]: '36',
    [COUNTRIES.INDONESIA]: '62',
    [COUNTRIES.IRELAND]: '353',
    [COUNTRIES.ISRAEL]: '972',
    [COUNTRIES.ISLE_OF_MAN]: '44-1624',
    [COUNTRIES.INDIA]: '91',
    [COUNTRIES.BRITISH_INDIAN_OCEAN_TERRITORY]: '246',
    [COUNTRIES.IRAQ]: '964',
    [COUNTRIES.IRAN]: '98',
    [COUNTRIES.ICELAND]: '354',
    [COUNTRIES.ITALY]: '39',
    [COUNTRIES.JERSEY]: '44-1534',
    [COUNTRIES.JAMAICA]: '1-876',
    [COUNTRIES.JORDAN]: '962',
    [COUNTRIES.JAPAN]: '81',
    [COUNTRIES.KENYA]: '254',
    [COUNTRIES.KYRGYZSTAN]: '996',
    [COUNTRIES.CAMBODIA]: '855',
    [COUNTRIES.KIRIBATI]: '686',
    [COUNTRIES.COMOROS]: '269',
    [COUNTRIES.SAINT_KITTS_AND_NEVIS]: '1-869',
    [COUNTRIES.NORTH_KOREA]: '850',
    [COUNTRIES.KOREA]: '82',
    [COUNTRIES.KUWAIT]: '965',
    [COUNTRIES.CAYMAN_ISLANDS]: '1-345',
    [COUNTRIES.KAZAKHSTAN]: '7',
    [COUNTRIES.LAO]: '856',
    [COUNTRIES.LEBANON]: '961',
    [COUNTRIES.SAINT_LUCIA]: '1-758',
    [COUNTRIES.LIECHTENSTEIN]: '423',
    [COUNTRIES.SRI_LANKA]: '94',
    [COUNTRIES.LIBERIA]: '231',
    [COUNTRIES.LESOTHO]: '266',
    [COUNTRIES.LITHUANIA]: '370',
    [COUNTRIES.LUXEMBOURG]: '352',
    [COUNTRIES.LATVIA]: '371',
    [COUNTRIES.LIBYA]: '218',
    [COUNTRIES.MOROCCO]: '212',
    [COUNTRIES.MONACO]: '377',
    [COUNTRIES.MOLDOVA]: '373',
    [COUNTRIES.MONTENEGRO]: '382',
    [COUNTRIES.SAINT_MARTIN]: '590',
    [COUNTRIES.MADAGASCAR]: '261',
    [COUNTRIES.MARSHALL_ISLANDS]: '692',
    [COUNTRIES.MACEDONIA]: '389',
    [COUNTRIES.MALI]: '223',
    [COUNTRIES.MYANMAR]: '95',
    [COUNTRIES.MONGOLIA]: '976',
    [COUNTRIES.NORTHERN_MARIANA_ISLANDS]: '1-670',
    [COUNTRIES.MARTINIQUE]: '596',
    [COUNTRIES.MAURITANIA]: '222',
    [COUNTRIES.MONTSERRAT]: '1-664',
    [COUNTRIES.MALTA]: '356',
    [COUNTRIES.MAURITIUS]: '230',
    [COUNTRIES.MALDIVES]: '960',
    [COUNTRIES.MALAWI]: '265',
    [COUNTRIES.MEXICO]: '52',
    [COUNTRIES.MALAYSIA]: '60',
    [COUNTRIES.MOZAMBIQUE]: '258',
    [COUNTRIES.NAMIBIA]: '264',
    [COUNTRIES.NEW_CALEDONIA]: '687',
    [COUNTRIES.NIGER]: '227',
    [COUNTRIES.NORFOLK_ISLAND]: '672',
    [COUNTRIES.NIGERIA]: '234',
    [COUNTRIES.NICARAGUA]: '505',
    [COUNTRIES.NETHERLANDS]: '31',
    [COUNTRIES.NORWAY]: '47',
    [COUNTRIES.NEPAL]: '977',
    [COUNTRIES.NAURU]: '674',
    [COUNTRIES.NIUE]: '683',
    [COUNTRIES.NEW_ZEALAND]: '64',
    [COUNTRIES.OMAN]: '968',
    [COUNTRIES.PANAMA]: '507',
    [COUNTRIES.PERU]: '51',
    [COUNTRIES.FRENCH_POLYNESIA]: '689',
    [COUNTRIES.PAPUA_NEW_GUINEA]: '675',
    [COUNTRIES.PHILIPPINES]: '63',
    [COUNTRIES.PAKISTAN]: '92',
    [COUNTRIES.POLAND]: '48',
    [COUNTRIES.SAINT_PIERRE_AND_MIQUELON]: '508',
    [COUNTRIES.PITCAIRN]: '64',
    [COUNTRIES.PUERTO_RICO]: '1',
    [COUNTRIES.PALESTINE]: '970',
    [COUNTRIES.PORTUGAL]: '351',
    [COUNTRIES.PALAU]: '680',
    [COUNTRIES.PARAGUAY]: '595',
    [COUNTRIES.QATAR]: '974',
    [COUNTRIES.REUNION]: '262',
    [COUNTRIES.ROMANIA]: '40',
    [COUNTRIES.SERBIA]: '381',
    [COUNTRIES.RUSSIAN_FEDERATION]: '7',
    [COUNTRIES.RWANDA]: '250',
    [COUNTRIES.SAUDI_ARABIA]: '966',
    [COUNTRIES.SOLOMON_ISLANDS]: '677',
    [COUNTRIES.SEYCHELLES]: '248',
    [COUNTRIES.SUDAN]: '249',
    [COUNTRIES.SWEDEN]: '46',
    [COUNTRIES.SINGAPORE]: '65',
    [COUNTRIES.SAINT_HELENA]: '290',
    [COUNTRIES.SLOVENIA]: '386',
    [COUNTRIES.SVALBARD_AND_JAN_MAYEN]: '47',
    [COUNTRIES.SLOVAKIA]: '421',
    [COUNTRIES.SIERRA_LEONE]: '232',
    [COUNTRIES.SAN_MARINO]: '378',
    [COUNTRIES.SENEGAL]: '221',
    [COUNTRIES.SOMALIA]: '252',
    [COUNTRIES.SURINAME]: '597',
    [COUNTRIES.SOUTH_SUDAN]: '211',
    [COUNTRIES.SAO_TOME_AND_PRINCIPE]: '239',
    [COUNTRIES.EL_SALVADOR]: '503',
    [COUNTRIES.SINT_MAARTEN]: '590',
    [COUNTRIES.SYRIAN_ARAB_REPUBLIC]: '963',
    [COUNTRIES.TURKS_AND_CAICOS_ISLANDS]: '1-649',
    [COUNTRIES.CHAD]: '235',
    [COUNTRIES.FRENCH_SOUTHERN_TERRITORIES]: '262',
    [COUNTRIES.TOGO]: '228',
    [COUNTRIES.THAILAND]: '66',
    [COUNTRIES.TAJIKISTAN]: '992',
    [COUNTRIES.TOKELAU]: '690',
    [COUNTRIES.TIMOR_LESTE]: '670',
    [COUNTRIES.TURKMENISTAN]: '993',
    [COUNTRIES.TUNISIA]: '216',
    [COUNTRIES.TONGA]: '676',
    [COUNTRIES.TURKEY]: '90',
    [COUNTRIES.TRINIDAD_AND_TOBAGO]: '1-868',
    [COUNTRIES.TUVALU]: '688',
    [COUNTRIES.TAIWAN]: '886',
    [COUNTRIES.TANZANIA]: '255',
    [COUNTRIES.UKRAINE]: '380',
    [COUNTRIES.UGANDA]: '256',
    [COUNTRIES.UNITED_STATES_MINOR_OUTLYING_ISLANDS]: '1',
    [COUNTRIES.UNITED_STATES]: '1',
    [COUNTRIES.URUGUAY]: '598',
    [COUNTRIES.UZBEKISTAN]: '998',
    [COUNTRIES.SAINT_VINCENT_AND_THE_GRENADINES]: '1-784',
    [COUNTRIES.VENEZUELA]: '58',
    [COUNTRIES.VIRGIN_ISLANDS_BRITISH]: '1-284',
    [COUNTRIES.VIRGIN_ISLANDS_US]: '1-340',
    [COUNTRIES.VIET_NAM]: '84',
    [COUNTRIES.VANUATU]: '678',
    [COUNTRIES.WALLIS_AND_FUTUNA]: '681',
    [COUNTRIES.SAMOA]: '685',
    [COUNTRIES.YEMEN]: '967',
    [COUNTRIES.MAYOTTE]: '262',
    [COUNTRIES.SOUTH_AFRICA]: '27',
    [COUNTRIES.ZAMBIA]: '260',
    [COUNTRIES.ZIMBABWE]: '263',
} as const;
