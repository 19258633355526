import {computed} from 'vue';
import {getCookie} from 'typescript-cookie';

export function useSetupVars() {

    const jwtFromCookie = getCookie('jwt');
    const jwt = computed(() => typeof jwtFromCookie !== 'undefined' ? jwtFromCookie : '');
    const csrfToken = computed(() => {
        const element: HTMLMetaElement|null = document.querySelector('meta[name="csrfToken"]');
        return element ? element.content : '';
    });

    return {
        jwt,
        csrfToken,
    };

}
