import {computed} from 'vue';
import {RequestMethods, useRawAxios, ValidResponse} from './useRawAxios';
import {Store} from 'vuex';

export function useAxios (store: Store<unknown>|null = null) {

    const vuexStore = store;

    const {
        axiosActionInternal,
    } = useRawAxios();

    const hostname = computed(() => {
        const api = import.meta.env.DEV ? '//api-app.' : '//api.';
        return window.location.protocol + api + window.location.origin.split('.').slice(1).join('.');
    });

    const axiosSend = (method: RequestMethods) => {
        return function<ResponseType extends ValidResponse<unknown>>
        (
            url: string,
            params: FormData | Record<string, unknown> = {},
            rawResponse = false,
            additionalHeaders: Record<string, unknown> = {},
        ) {
            if(method === 'DELETE') {
                if (params instanceof FormData) {
                    params.forEach((value, key) => (additionalHeaders[key] = value));
                }else{
                    additionalHeaders = {...additionalHeaders, ...params};
                }
            }

            return axiosActionInternal<ResponseType>({
                requestType: method,
                url: `${hostname.value}/v1${url}`,
                params: params,
                additionalHeaders: additionalHeaders,
            }, rawResponse).then(response => {
                return Promise.resolve(response);
            }).catch(exception => {
                return Promise.reject(exception);
            });
        };
    };

    const axiosGet = axiosSend('GET');
    const axiosPost = axiosSend('POST');
    const axiosPut = axiosSend('PUT');
    const axiosPatch = axiosSend('PATCH');
    const axiosDelete = axiosSend('DELETE');

    const axiosGetIntoStore = async (endpoint: string, action: string) => {
        const result = await axiosGet(endpoint);
        return vuexStore.dispatch(action, result);
    };

    return {
        axiosSend,
        axiosGet,
        axiosPost,
        axiosPut,
        axiosPatch,
        axiosDelete,
        axiosGetIntoStore,
    };

}
