import {
    CURRENCY as EXTERNAL_CURRENCY,
    CURRENCY_SYMBOL as EXTERNAL_CURRENCY_SYMBOL,
    CURRENCY_CODE as EXTERNAL_CURRENCY_CODE,
    COUNTRIES as EXTERNAL_COUNTRIES,
    COUNTRIES_NAMES_CA_US as EXTERNAL_COUNTRIES_NAMES_CA_US,
    COUNTRIES_NAMES as EXTERNAL_COUNTRIES_NAMES,
    COUNTRIES_AREA_CODES as EXTERNAL_COUNTRIES_AREA_CODES,
} from '../../../utilities/constants';

export const CURRENCY: Record<string, number> = EXTERNAL_CURRENCY;
export const CURRENCY_SYMBOL: Record<string, string> = EXTERNAL_CURRENCY_SYMBOL;
export const CURRENCY_CODE: Record<string, string> = EXTERNAL_CURRENCY_CODE;

export const COUNTRIES: Record<string, string> = EXTERNAL_COUNTRIES;
export const COUNTRIES_NAMES: Record<string, string> = EXTERNAL_COUNTRIES_NAMES;
export const COUNTRIES_NAMES_CA_US: Record<string, string> = EXTERNAL_COUNTRIES_NAMES_CA_US;
export const COUNTRIES_AREA_CODES: Record<string, string> = EXTERNAL_COUNTRIES_AREA_CODES;

export const BRAND_THEMES: Record<string, string> = {
    RED_THEME: 'peach-theme',
    CYAN_THEME: 'cyan-theme',
    YELLOW_THEME: 'lemonade-theme',
    PURPLE_THEME: 'purple-theme',
    PRIMARY_THEME: 'purple-theme',
    MAGENTA_THEME: 'peach-theme',
    GREEN_THEME: 'green-theme',
    ORANGE_THEME: 'lemonade-theme',
    TEAL_THEME: 'cyan-theme',
    NEUTRALS_THEME: 'midnight-theme',
    MIDNIGHT_THEME: 'midnight-theme',
    PEACH_THEME: 'peach-theme',
    LEMONADE_THEME: 'lemonade-theme',
};

export const BRAND_COLORS: Record<string, string> = {
    [BRAND_THEMES.RED_THEME]: 'peach',
    [BRAND_THEMES.CYAN_THEME]: 'cyan',
    [BRAND_THEMES.YELLOW_THEME]: 'lemonade',
    [BRAND_THEMES.PURPLE_THEME]: 'purple',
    [BRAND_THEMES.PRIMARY_THEME]: 'purple',
    [BRAND_THEMES.MAGENTA_THEME]: 'peach',
    [BRAND_THEMES.GREEN_THEME]: 'green',
    [BRAND_THEMES.ORANGE_THEME]: 'lemonade',
    [BRAND_THEMES.TEAL_THEME]: 'cyan',
    [BRAND_THEMES.NEUTRALS_THEME]: 'midnight',
    [BRAND_THEMES.MIDNIGHT_THEME]: 'midnight',
    [BRAND_THEMES.PEACH_THEME]: 'peach',
    [BRAND_THEMES.LEMONADE_THEME]: 'lemonade',
};

export const FEEDBACK_SOURCE: Record<string, number> = {
    ONLINE_STORE: 1,
};

export const FEEDBACK_SOURCE_NAME: Record<string, string> = {
    [FEEDBACK_SOURCE.ONLINE_STORE]: 'Online Store',
};

export const FEEDBACK_TYPE: Record<string, number> = {
    APP: 1,
    MERCHANT: 2,
};

export const FEEDBACK_TYPE_NAME: Record<string, string> = {
    [FEEDBACK_TYPE.APP]: 'Website',
    [FEEDBACK_TYPE.MERCHANT]: 'Business',
};

export const FEEDBACK_RATING: Record<string, number> = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
};

export const FEEDBACK_RATING_NAME: Record<string, string> = {
    [FEEDBACK_RATING.ONE]: '1',
    [FEEDBACK_RATING.TWO]: '2',
    [FEEDBACK_RATING.THREE]: '3',
    [FEEDBACK_RATING.FOUR]: '4',
    [FEEDBACK_RATING.FIVE]: '5',
};

export const PAYMENT_PLAN_BILLING_PERIOD = {
    DAILY: 1,
    WEEKLY: 2,
    MONTHLY: 3,
    YEARLY: 4,
};

export const PAYMENT_PLAN_TYPE = {
    BILL_ON_SIGN_UP: 1, // referred to as SUBSCRIPTION in subscription management
    PICK_A_DAY_TO_BILL: 2, // referred to as CYCLE in subscription management
};

export const PAYMENT_PLAN_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
};

export const PAYMENT_PLAN_TAX_TYPE = {
    NONE: 1,
    CUSTOMER_LOCATION: 2,
    MERCHANT_LOCATION: 3,
};

export const SUBSCRIPTION_V2_STATUS = {
    ACTIVE: 1,
    INACTIVE_PAUSED: 2,
    CANCELLED: 3,
    TERM_ENDING: 4,
    TERM_ENDED: 5,
};

export const SUBSCRIPTION_V2_STATUS_NAMES = {
    [SUBSCRIPTION_V2_STATUS.ACTIVE]: 'Active',
    [SUBSCRIPTION_V2_STATUS.INACTIVE_PAUSED]: 'Inactive or paused',
    [SUBSCRIPTION_V2_STATUS.CANCELLED]: 'Cancelled',
    [SUBSCRIPTION_V2_STATUS.TERM_ENDING]: 'Term ending',
    [SUBSCRIPTION_V2_STATUS.TERM_ENDED]: 'Term ended',
};

export const ADD_ON_V2_FREQUENCY = {
    RECURRING: 'recurring',
    ONE_TIME: 'one_time',
};

export const ADD_ON_V2_FREQUENCY_NAMES = {
    [ADD_ON_V2_FREQUENCY.RECURRING]: 'Recurring',
    [ADD_ON_V2_FREQUENCY.ONE_TIME]: 'One-time',
};

export enum BillingWeekDayType {
    Invalid = 0,
    Sunday = 1,
    Monday = 2,
    Tuesday = 3,
    Wednesday = 4,
    Thursday = 5,
    Friday = 6,
    Saturday = 7,
}

export const SUBSCRIPTION_STATUS = {
    ACTIVE: 1,
    TRIAL: 2,
    INACTIVE: 0,
};

export const SUBSCRIPTION_STATUS_NAMES = {
    [SUBSCRIPTION_STATUS.INACTIVE]: 'Cancelled',
    [SUBSCRIPTION_STATUS.ACTIVE]: 'Active',
    [SUBSCRIPTION_STATUS.TRIAL]: 'Trial',
};

export const RECURRING_BILLING_METHOD = {
    CYCLE: 0,
    PRORATA: 1,
    SUBSCRIPTION: 2,
};

export const RECURRING_BILLING_METHOD_NAMES = {
    [RECURRING_BILLING_METHOD.CYCLE]: 'Cycle Based',
    [RECURRING_BILLING_METHOD.PRORATA]: 'Pro-Rata Based',
    [RECURRING_BILLING_METHOD.SUBSCRIPTION]: 'Subscription Based',
};

export const ORDER_TYPE: Record<string, number> = {
    ESTIMATE: 1,
    QUOTE: 2,
    ORDER: 3,
    PURCHASE_ORDER: 4,
    INVOICE: 5,
    STATEMENT: 6,
    REGISTRATION: 7,
    CREDIT: 8,
} as const;

export const ORDER_TYPE_NAMES: Record<string, string> = {
    [ORDER_TYPE.ESTIMATE]: 'Estimate',
    [ORDER_TYPE.QUOTE]: 'Quote',
    [ORDER_TYPE.ORDER]: 'Order',
    [ORDER_TYPE.PURCHASE_ORDER]: 'Purchase Order',
    [ORDER_TYPE.INVOICE]: 'Invoice',
    [ORDER_TYPE.STATEMENT]: 'Statement',
    [ORDER_TYPE.REGISTRATION]: 'Registration',
    [ORDER_TYPE.CREDIT]: 'Credit',
} as const;

export const INVOICE_CUSTOMER_TYPE: Record<string, number> = {
    CUSTOMER: 1,
    CLIENT: 2,
    PATIENT: 3,
    ACCOUNT: 4,
} as const;

export const INVOICE_CUSTOMER_NAME: Record<string, string> = {
    [INVOICE_CUSTOMER_TYPE.CUSTOMER]: 'Customer',
    [INVOICE_CUSTOMER_TYPE.CLIENT]: 'Client',
    [INVOICE_CUSTOMER_TYPE.PATIENT]: 'Patient',
    [INVOICE_CUSTOMER_TYPE.ACCOUNT]: 'Account',
} as const;

export const INVOICE_PRICE_TYPE: Record<string, number> = {
    PRICE: 1,
    FEE: 2,
    CHARGE: 3,
    COST: 4,
} as const;

export const INVOICE_PRICE_TYPE_NAMES: Record<string, string> = {
    [INVOICE_PRICE_TYPE.PRICE]: 'Price',
    [INVOICE_PRICE_TYPE.FEE]: 'Fee',
    [INVOICE_PRICE_TYPE.CHARGE]: 'Charge',
    [INVOICE_PRICE_TYPE.COST]: 'Cost',
} as const;

export const CUSTOM_FIELD_TYPE: Record<string, number> = {
    TEXTBOX: 1,
    DROPDOWN: 2,
    CHECKBOX: 3,
    RADIO_BUTTON: 4,
} as const;

export const CUSTOM_FIELD_TYPE_NAMES: Record<string, string> = {
    [CUSTOM_FIELD_TYPE.TEXTBOX]: 'Text Box',
    [CUSTOM_FIELD_TYPE.DROPDOWN]: 'Dropdown',
    [CUSTOM_FIELD_TYPE.CHECKBOX]: 'Checkbox',
    [CUSTOM_FIELD_TYPE.RADIO_BUTTON]: 'Radio Button',
} as const;

export const PROMO_BUY_TYPE: Record<string, number> = {
    MIN_ITEM_QTY: 1,
    MIN_PURCHASE: 2,
} as const;

export const PROMO_GET_TYPE: Record<string, number> = {
    QUANTITY_FREE: 1,
    AMOUNT_OFF: 2,
    PERCENTAGE_OFF: 3,
} as const;

export const DEPOSIT_STATUS: Record<string, number> = {
    NONE: 0,
    DUE: 1,
    PAID: 2,
} as const;

export const DEPOSIT_STATUS_NAME: Record<string, string> = {
    [DEPOSIT_STATUS.NONE]: 'No Deposit',
    [DEPOSIT_STATUS.DUE]: 'Due',
    [DEPOSIT_STATUS.PAID]: 'Paid',
} as const;

export const DISCOUNT_CODE_TYPES: Record<string, number> = {
    PERCENTAGE_DISCOUNT: 1,
    AMOUNT_DISCOUNT: 2,
    FREE_SHIPPING: 3,
} as const;

export const DISCOUNT_CODE_TYPES_NAME: Record<string, string> = {
    [DISCOUNT_CODE_TYPES.PERCENTAGE_DISCOUNT]: 'Percentage Discount',
    [DISCOUNT_CODE_TYPES.AMOUNT_DISCOUNT]: 'Amount Discount',
    [DISCOUNT_CODE_TYPES.FREE_SHIPPING]: 'Free Shipping',
} as const;

export const STOCK_TYPE: Record<string, number> = {
    BY_VARIANT: 0,
    BY_PRODUCT: 1,
};

export const VARIANT_STATUS: Record<string, number> = {
    IS_PRODUCT: 0,
    IS_VARIANT: 1,
};

export const TRANSACTION_STATUS: Record<string, number> = {
    APPROVED: 1,
    DECLINED: 2,
    IN_PROGRESS: 3,
    EXPIRED: 4,
} as const;

export const TRANSACTION_STATUS_NAMES: Record<string, string> = {
    [TRANSACTION_STATUS.APPROVED]: 'APPROVED',
    [TRANSACTION_STATUS.DECLINED]: 'DECLINED',
    [TRANSACTION_STATUS.IN_PROGRESS]: 'IN PROGRESS',
    [TRANSACTION_STATUS.EXPIRED]: 'EXPIRED',
} as const;

export const TRANSACTION_TYPES: Record<string, number> = {
    PURCHASE: 1,
    PREAUTH: 2,
    CAPTURE: 3,
    REFUND: 4,
    VOID: 5,
    SETTLE: 6,
    VERIFY: 7,
    REVERSE: 8,
    BALANCE_INQUIRY: 9,
} as const;

export const TRANSACTION_TYPES_NAMES: Record<string, string> = {
    [TRANSACTION_TYPES.PURCHASE]: 'Purchase',
    [TRANSACTION_TYPES.PREAUTH]: 'Preauth',
    [TRANSACTION_TYPES.CAPTURE]: 'Capture',
    [TRANSACTION_TYPES.REFUND]: 'Refund',
    [TRANSACTION_TYPES.VOID]: 'Void',
    [TRANSACTION_TYPES.SETTLE]: 'Settle',
    [TRANSACTION_TYPES.VERIFY]: 'Verify',
    [TRANSACTION_TYPES.REVERSE]: 'Reverse',
    [TRANSACTION_TYPES.BALANCE_INQUIRY]: 'Balance Inquiry',
} as const;

export const TRANSACTION_EFT_TYPES: Record<string, number> = {
    WITHDRAWAL: 1,
    DEPOSIT: 2,
    SETTLE: 3,
    REVERSE: 4,
    REFUND: 5,
} as const;

export const TRANSACTION_EFT_TYPES_NAMES: Record<string, string> = {
    [TRANSACTION_EFT_TYPES.WITHDRAWAL]: 'Withdrawal',
    [TRANSACTION_EFT_TYPES.DEPOSIT]: 'Deposit',
    [TRANSACTION_EFT_TYPES.SETTLE]: 'Settle',
    [TRANSACTION_EFT_TYPES.REVERSE]: 'Reverse',
    [TRANSACTION_EFT_TYPES.REFUND]: 'Refund',
} as const;

export const TRANSACTION_EFT_STATUS: Record<string, number> = {
    APPROVED: 1,
    DECLINED: 2,
    IN_PROGRESS: 3,
    CANCELLED: 4,
    PENDING: 5,
} as const;

export const TRANSACTION_EFT_STATUS_NAME: Record<string, string> = {
    [TRANSACTION_EFT_STATUS.APPROVED]: 'Withdrawal',
    [TRANSACTION_EFT_STATUS.DECLINED]: 'Deposit',
    [TRANSACTION_EFT_STATUS.IN_PROGRESS]: 'Settle',
    [TRANSACTION_EFT_STATUS.CANCELLED]: 'Reverse',
    [TRANSACTION_EFT_STATUS.PENDING]: 'Refund',
} as const;

export const TRANSACTION_TENDER_TYPES: Record<string, number> = {
    CASH: 1,
    CHECK: 2,
    BANK_TRANSFER: 3,
    GIFT_CARD: 4,
    COD: 5,
    CREDIT_CARD_EXTERNAL: 6,
    DEBIT_CARD_EXTERNAL: 7,
    MISC: 8,
    CASH_REFUND: 9,
} as const;

export const TRANSACTION_TENDER_TYPES_NAMES: Record<string, string> = {
    [TRANSACTION_TENDER_TYPES.CASH]: 'Cash',
    [TRANSACTION_TENDER_TYPES.CHECK]: 'Check',
    [TRANSACTION_TENDER_TYPES.BANK_TRANSFER]: 'Bank Transfer',
    [TRANSACTION_TENDER_TYPES.GIFT_CARD]: 'Gift Card',
    [TRANSACTION_TENDER_TYPES.COD]: 'COD',
    [TRANSACTION_TENDER_TYPES.CREDIT_CARD_EXTERNAL]: 'Credit Card (External)',
    [TRANSACTION_TENDER_TYPES.DEBIT_CARD_EXTERNAL]: 'Debit Card (External)',
    [TRANSACTION_TENDER_TYPES.MISC]: 'Misc',
    [TRANSACTION_TENDER_TYPES.CASH_REFUND]: 'Cash Refund',
} as const;

export const CANADIAN_PROVINCES: Record<string, string> = {
    ALBERTA: 'Alberta',
    BRITISH_COLUMBIA: 'British Columbia',
    MANITOBA: 'Manitoba',
    NEW_BRUNSWICK: 'New Brunswick',
    NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland and Labrador',
    NORTHWEST_TERRITORIES: 'Northwest Territories',
    NOVA_SCOTIA: 'Nova Scotia',
    NUNAVUT: 'Nunavut',
    ONTARIO: 'Ontario',
    PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
    QUEBEC: 'Quebec',
    SASKATCHEWAN: 'Saskatchewan',
    YUKON: 'Yukon',
} as const;

export const CANADIAN_PROVINCES_ABBREVIATED: Record<string, string> = {
    AB: CANADIAN_PROVINCES.ALBERTA,
    BC: CANADIAN_PROVINCES.BRITISH_COLUMBIA,
    MB: CANADIAN_PROVINCES.MANITOBA,
    NB: CANADIAN_PROVINCES.NEW_BRUNSWICK,
    NL: CANADIAN_PROVINCES.NEWFOUNDLAND_AND_LABRADOR,
    NT: CANADIAN_PROVINCES.NORTHWEST_TERRITORIES,
    NS: CANADIAN_PROVINCES.NOVA_SCOTIA,
    NU: CANADIAN_PROVINCES.NUNAVUT,
    ON: CANADIAN_PROVINCES.ONTARIO,
    PE: CANADIAN_PROVINCES.PRINCE_EDWARD_ISLAND,
    QC: CANADIAN_PROVINCES.QUEBEC,
    SK: CANADIAN_PROVINCES.SASKATCHEWAN,
    YK: CANADIAN_PROVINCES.YUKON,
} as const;

export const US_STATES: Record<string, string> = {
    ALABAMA: 'Alabama',
    ALASKA: 'Alaska',
    ARIZONA: 'Arizona',
    ARKANSAS: 'Arkansas',
    CALIFORNIA: 'California',
    COLORADO: 'Colorado',
    CONNECTICUT: 'Connecticut',
    DELAWARE: 'Delaware',
    FLORIDA: 'Florida',
    GEORGIA: 'Georgia',
    HAWAII: 'Hawaii',
    IDAHO: 'Idaho',
    ILLINOIS: 'Illinois',
    INDIANA: 'Indiana',
    IOWA: 'Iowa',
    KANSAS: 'Kansas',
    KENTUCKY: 'Kentucky',
    LOUISIANA: 'Louisiana',
    MAINE: 'Maine',
    MARYLAND: 'Maryland',
    MASSACHUSETTS: 'Massachusetts',
    MICHIGAN: 'Michigan',
    MINNESOTA: 'Minnesota',
    MISSISSIPPI: 'Mississippi',
    MISSOURI: 'Missouri',
    MONTANA: 'Montana',
    NEBRASKA: 'Nebraska',
    NEVADA: 'Nevada',
    NEW_HAMPSHIRE: 'New Hampshire',
    NEW_JERSEY: 'New Jersey',
    NEW_MEXICO: 'New Mexico',
    NEW_YORK: 'New York',
    NORTH_CAROLINA: 'North Carolina',
    NORTH_DAKOTA: 'North Dakota',
    OHIO: 'Ohio',
    OKLAHOMA: 'Oklahoma',
    OREGON: 'Oregon',
    PENNSYLVANIA: 'Pennsylvania',
    RHODE_ISLAND: 'Rhode Island',
    SOUTH_CAROLINA: 'South Carolina',
    SOUTH_DAKOTA: 'South Dakota',
    TENNESSEE: 'Tennessee',
    TEXAS: 'Texas',
    UTAH: 'Utah',
    VERMONT: 'Vermont',
    VIRGINIA: 'Virginia',
    WASHINGTON: 'Washington',
    DISTRICT_OF_COLUMBIA: 'Washington, D.C.',
    WEST_VIRGINIA: 'West Virginia',
    WISCONSIN: 'Wisconsin',
    WYOMING: 'Wyoming',
    // AMERICAN_SAMOA: 'American Samoa',
    // GUAM: 'Guam',
    // NORTHERN_MARIANA_ISLANDS: 'Northern Mariana Islands',
    // PUERTO_RICO: 'Puerto Rico',
    // VIRGIN_ISLANDS_US: 'U.S. Virgin Islands',
    // ARMED_FORCES_AFRICA: 'Armed Forces Africa',
    // ARMED_FORCES_AMERICAS: 'Armed Forces Americas',
    // ARMED_FORCES_CANADA: 'Armed Forces Canada',
    // ARMED_FORCES_EUROPE: 'Armed Forces Europe',
    // ARMED_FORCES_MIDDLE_EAST: 'Armed Forces Middle East',
    // ARMED_FORCES_PACIFIC: 'Armed Forces Pacific',
} as const;

export const US_STATES_ABBREVIATION = {
    [US_STATES.ALABAMA]: 'AL',
    [US_STATES.ALASKA]: 'AK',
    [US_STATES.ARIZONA]: 'AZ',
    [US_STATES.ARKANSAS]: 'AR',
    [US_STATES.CALIFORNIA]: 'CA',
    [US_STATES.COLORADO]: 'CO',
    [US_STATES.CONNECTICUT]: 'CT',
    [US_STATES.DELAWARE]: 'DE',
    [US_STATES.FLORIDA]: 'FL',
    [US_STATES.GEORGIA]: 'GA',
    [US_STATES.HAWAII]: 'HI',
    [US_STATES.IDAHO]: 'ID',
    [US_STATES.ILLINOIS]: 'IL',
    [US_STATES.INDIANA]: 'IN',
    [US_STATES.IOWA]: 'IA',
    [US_STATES.KANSAS]: 'KS',
    [US_STATES.KENTUCKY]: 'KY',
    [US_STATES.LOUISIANA]: 'LA',
    [US_STATES.MAINE]: 'ME',
    [US_STATES.MARYLAND]: 'MD',
    [US_STATES.MASSACHUSETTS]: 'MA',
    [US_STATES.MICHIGAN]: 'MI',
    [US_STATES.MINNESOTA]: 'MN',
    [US_STATES.MISSISSIPPI]: 'MS',
    [US_STATES.MISSOURI]: 'MO',
    [US_STATES.MONTANA]: 'MT',
    [US_STATES.NEBRASKA]: 'NE',
    [US_STATES.NEVADA]: 'NV',
    [US_STATES.NEW_HAMPSHIRE]: 'NH',
    [US_STATES.NEW_JERSEY]: 'NJ',
    [US_STATES.NEW_MEXICO]: 'NM',
    [US_STATES.NEW_YORK]: 'NY',
    [US_STATES.NORTH_CAROLINA]: 'NC',
    [US_STATES.NORTH_DAKOTA]: 'ND',
    [US_STATES.OHIO]: 'OH',
    [US_STATES.OKLAHOMA]: 'OK',
    [US_STATES.OREGON]: 'OR',
    [US_STATES.PENNSYLVANIA]: 'PA',
    [US_STATES.RHODE_ISLAND]: 'RI',
    [US_STATES.SOUTH_CAROLINA]: 'SC',
    [US_STATES.SOUTH_DAKOTA]: 'SD',
    [US_STATES.TENNESSEE]: 'TN',
    [US_STATES.TEXAS]: 'TX',
    [US_STATES.UTAH]: 'UT',
    [US_STATES.VERMONT]: 'VT',
    [US_STATES.VIRGINIA]: 'VA',
    [US_STATES.WASHINGTON]: 'WA',
    [US_STATES.DISTRICT_OF_COLUMBIA]: 'DC',
    [US_STATES.WEST_VIRGINIA]: 'WV',
    [US_STATES.WISCONSIN]: 'WI',
    [US_STATES.WYOMING]: 'WY',
    // [US_STATES.AMERICAN_SAMOA]: 'AS',
    // [US_STATES.GUAM]: 'GU',
    // [US_STATES.NORTHERN_MARIANA_ISLANDS]: 'MP',
    // [US_STATES.PUERTO_RICO]: 'PR',
    // [US_STATES.VIRGIN_ISLANDS_US]: 'VI',
    // [US_STATES.ARMED_FORCES_AFRICA]: 'AE',
    // [US_STATES.ARMED_FORCES_AMERICAS]: 'AA',
    // [US_STATES.ARMED_FORCES_CANADA]: 'AE',
    // [US_STATES.ARMED_FORCES_EUROPE]: 'AE',
    // [US_STATES.ARMED_FORCES_MIDDLE_EAST]: 'AE',
    // [US_STATES.ARMED_FORCES_PACIFIC]: 'AP',
} as const;

export type CountryCode = typeof COUNTRIES[keyof typeof COUNTRIES];
export type CanadianProvinceName = typeof CANADIAN_PROVINCES[keyof typeof CANADIAN_PROVINCES];
export type UsStateName = typeof US_STATES[keyof typeof US_STATES];
export type UsStateCode = typeof US_STATES_ABBREVIATION[UsStateName];

export const PROVINCES_BY_COUNTRY = {
    [COUNTRIES.UNITED_STATES]: US_STATES,
    [COUNTRIES.CANADA]:CANADIAN_PROVINCES,
};

export const PROVINCES_BY_COUNTRY_NAMES = {
    [COUNTRIES_NAMES.UNITED_STATES]: US_STATES,
    [COUNTRIES_NAMES.CANADA]: CANADIAN_PROVINCES,
};

export const BANK_ACCOUNT_TYPES = {
    CHECKING: 1,
    SAVINGS: 2,
} as const;
export type BankAccountType = typeof BANK_ACCOUNT_TYPES[keyof typeof BANK_ACCOUNT_TYPES];

export const ACCOUNT_FORMAT_LABELS = [
    {'text': 'Checking', 'value': 'CHECKING'},
    {'text': 'Savings', 'value': 'SAVINGS'},
];

export const ACCOUNT_TYPE_CATEGORY_LABELS = [
    {'text': 'Personal', 'value': 'PERSONAL'},
    {'text': 'Business', 'value': 'CORPORATE'},
];

export const BANK_ACCOUNT_TYPES_NAME = {
    [BANK_ACCOUNT_TYPES.CHECKING]: 'Checking',
    [BANK_ACCOUNT_TYPES.SAVINGS]: 'Savings',
} as const;
export type BankAccountName = typeof BANK_ACCOUNT_TYPES_NAME[keyof typeof BANK_ACCOUNT_TYPES_NAME];

export const BANK_ACCOUNT_TYPES_CODE = {
    [BANK_ACCOUNT_TYPES.CHECKING]: 'CHECKING',
    [BANK_ACCOUNT_TYPES.SAVINGS]: 'SAVINGS',
} as const;
export type BankAccountCode = typeof BANK_ACCOUNT_TYPES_CODE[keyof typeof BANK_ACCOUNT_TYPES_CODE];

export const BANK_ACCOUNT_CORPORATE_TYPES = {
    PERSONAL: 1,
    CORPORATE: 2,
} as const;
export type BankAccountCorporateType = typeof BANK_ACCOUNT_CORPORATE_TYPES[keyof typeof BANK_ACCOUNT_CORPORATE_TYPES];

export const BANK_ACCOUNT_CORPORATE_TYPES_NAME = {
    [BANK_ACCOUNT_CORPORATE_TYPES.PERSONAL]: 'Personal',
    [BANK_ACCOUNT_CORPORATE_TYPES.CORPORATE]: 'Corporate',
} as const;
export const BANK_ACCOUNT_CORPORATE_TYPES_CODE = {
    [BANK_ACCOUNT_CORPORATE_TYPES.PERSONAL]: 'PERSONAL',
    [BANK_ACCOUNT_CORPORATE_TYPES.CORPORATE]: 'CORPORATE',
} as const;
export type BankAccountCorporateName = typeof BANK_ACCOUNT_CORPORATE_TYPES_NAME[keyof typeof BANK_ACCOUNT_CORPORATE_TYPES_NAME];

export const SOCIAL_MEDIA_HANDLE_RESPONSE: Record<string, number> = {
    GOOGLE_FAIL: 0,
    SUCCESS: 1,
    FACEBOOK_SUCCESS: 2,
    FACEBOOK_FAIL: 3,
    SAFARI_WARNING: 4,
    SAFARI_FAIL: 5,
    BUTTON_LOADED: 6,
} as const;

export const REGISTER_FORM = {
    MAIN: 1,
    SHIPPING: 2,
    BILLING: 3,
    CARD: 4,
    BANK_ACCOUNT: 5,
};

export const REGISTER_FORM_PATH = {
    [REGISTER_FORM.MAIN]: '/portal/register/main',
    [REGISTER_FORM.SHIPPING]: '/portal/register/shipping',
    [REGISTER_FORM.BILLING]: '/portal/register/billing',
    [REGISTER_FORM.CARD]: '/portal/register/card',
    [REGISTER_FORM.BANK_ACCOUNT]: '/portal/register/bank-account',
};

export const TIP_FORMAT = {
    PERCENTAGE: 0,
    AMOUNT: 1,
};

export const TIP_FORMAT_TEXT = {
    [TIP_FORMAT.PERCENTAGE]: 'percentage',
    [TIP_FORMAT.AMOUNT]: 'dollar',
};

export const TIP_MODE = {
    RECOMMENDED_AMOUNTS: 1,
    CUSTOM_AMOUNT: 2,
    NO_TIP: 3,
};

export const TIP_DEFAULT: Record<string, string> = {
    TEN: '10',
    FIFTEEN: '15',
    TWENTY: '20',
};

export const TIP_LABELS = [
    {'text': 'Percentage %', 'value':'percentage'},
    {'text':'Dollar amount $', 'value': 'dollar'},
];

export const STATUS_CODES: Record<string, number> = {
    OK_200: 200,
    CREATED_201: 201,
    ACCEPTED_202: 202,
    MOVED_301: 301,
    BAD_REQUEST_400: 400,
    UNAUTHORIZED_401: 401,
    FORBIDDEN_403: 403,
    NOT_FOUND_404: 404,
    UNPROCESSABLE_ENTITY_422: 422,
    TOO_MANY_REQUESTS_429: 429,
    INTERNAL_ERROR_500: 500,
    SERVICE_UNAVAILABLE_503: 503,
} as const;

export const OFFERING_TYPES: Record<string, number> = {
    PRODUCT: 1,
    PRODUCT_VARIANT: 2,
    ADD_ON: 3,
} as const;
export type OfferingType = typeof OFFERING_TYPES[keyof typeof OFFERING_TYPES];

export const OFFERING_TYPES_NAME: Record<string, string> = {
    [OFFERING_TYPES.PRODUCT]: 'Product',
    [OFFERING_TYPES.PRODUCT_VARIANT]: 'Product Variant',
    [OFFERING_TYPES.ADD_ON]: 'Add-on',
} as const;

export const DAYS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

export const LINKS: Record<string, string> = {
    CHECKOUT: '/checkout',
    CHECKOUT_LOGIN: '/checkout-login',
    REVIEW_ORDER: '/review-order',
    QUICK_ORDER: '/quick-order',
    REGISTER: '/portal/register',
    ADMIN: '/admin',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
 } as const;

export const ONBOARDING_LINKS = [
    {'text': 'business-details', 'url': '/admin/onboarding/business-details'},
    {'text': 'merchant-policies', 'url': '/admin/onboarding/merchant-policies'},
    {'text': 'checkout-details', 'url': '/admin/onboarding/checkout-details'},
    {'text': 'store-details', 'url': '/admin/onboarding/store-details'},
];


export const ONBOARDING_ROUTER_LINKS: Record<string, string> = {
    BUSINESS_DETAILS: '/onboarding/business-details',
    MERCHANT_POLICIES: '/onboarding/merchant-policies',
    CHECKOUT_DETAILS: '/onboarding/checkout-details',
    STORE_DETAILS: '/onboarding/store-details',
} as const;

export const ADMIN_DASHBOARD_LINKS: Record<string, string> = {
    OVERVIEW: '/dashboard/overview',
    BANNER: '/dashboard/welcome',
} as const;

export const ADMIN_SETTINGS_LINKS = [
    {'text': 'Business Details', 'url': '/settings/business-details'},
    {'text': 'Merchant Policies', 'url': '/settings/merchant-policies'},
    {'text': 'Customers', 'url': '/settings/customers'},
    {'text': 'Checkout Details', 'url': '/settings/checkout-details'},
    // {'text': 'Product & Inventory', 'url': '/settings/product-inventory'},
    {'text': 'Store Designer', 'url': '/settings/store-details'},
    {'text': 'Advanced', 'url': '/settings/advanced'},
];

export const TEXT_BLOCK_TEXT_ID: Record<string, string> = {
    CONTACT_US: 'contact-us',
    RETURN_POLICY: 'return-policy',
    MERCHANT_POLICY: 'merchant-policy',
    PRIVACY_POLICY: 'privacy-policy',
    SHIPPING_POLICY: 'shipping-policy',
    BANNER: 'banner',
    ABOUT_US: 'about-us',
    ADDITIONAL_DETAILS: 'additional-details',
} as const;

export const DELIVERY_OPTIONS = [
    {'text': 'Shipping or Delivery', 'value': 0},
    {'text': 'Pick Up', 'value': 1},
];

export const INTERVAL_OPTIONS: Record<string, number> = {
    IMMEDIATELY: 0,
    FIVE_MIN: 5,
    TEN_MIN: 10,
    FIFTEEN_MIN: 15,
    TWENTY_MIN: 20,
    THIRTY_MIN: 30,
    FOURTY_FIVE_MIN: 45,
    SIXTY_MIN: 60,
};

export const INTERVAL_OPTIONS_LABELS: Record<string, string> = {
    [INTERVAL_OPTIONS.IMMEDIATELY]: 'Available Immediately',
    [INTERVAL_OPTIONS.FIVE_MIN]: '5 minutes',
    [INTERVAL_OPTIONS.TEN_MIN]: '10 minutes',
    [INTERVAL_OPTIONS.FIFTEEN_MIN]: '15 minutes',
    [INTERVAL_OPTIONS.TWENTY_MIN]: '20 minutes',
    [INTERVAL_OPTIONS.THIRTY_MIN]: '30 minutes',
    [INTERVAL_OPTIONS.FOURTY_FIVE_MIN]: '45 minutes',
    [INTERVAL_OPTIONS.SIXTY_MIN]: '60 minutes',
};

export const ORDER_LIMIT_OPTIONS: Record<string, string> = {
    ZERO: '0',
    ONE: '1',
    TWO: '2',
    THREE: '3',
    FOUR: '4',
    FIVE: '5',
};

export const COLOUR_NEW_TO_LEGACY_MAPPING: Record<string, string> = {
    PRIMARY: 'primary',
    PURPLE: 'purple',
    MAGENTA: 'magenta',
    PEACH: 'red',
    GREEN: 'green',
    CYAN: 'cyan',
    TEAL: 'teal',
    YELLOW: 'yellow',
    ORANGE: 'orange',
    LEMONADE: 'yellow',
    RED: 'red',
    NEUTRALS: 'neutrals',
    MIDNIGHT: 'neutrals',
};

export const THEME_COLOUR_PICKER: Record<string, string> = {
    PURPLE: 'purple',
    MAGENTA: 'magenta',
    GREEN: 'green',
    CYAN: 'cyan',
    ORANGE: 'orange',
    NEUTRALS: 'neutrals',
};

export const COLOUR_LEGACY_TO_NEW_MAPPING: Record<string, string> = {
    PRIMARY: 'purple',
    PURPLE: 'purple',
    MAGENTA: 'magenta',
    PEACH: 'magenta',
    GREEN: 'green',
    CYAN: 'cyan',
    TEAL: 'cyan',
    YELLOW: 'orange',
    ORANGE: 'orange',
    LEMONADE: 'orange',
    RED: 'magenta',
    NEUTRALS: 'neutrals',
    MIDNIGHT: 'neutrals',
};

export const LEGACY_BRAND_THEMES: Record<string, string> = {
    [COLOUR_NEW_TO_LEGACY_MAPPING.PRIMARY]: 'primary-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.PURPLE]: 'purple-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.MAGENTA]: 'magenta-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.GREEN]: 'green-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.CYAN]: 'cyan-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.TEAL]: 'teal-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.YELLOW]: 'yellow-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.ORANGE]: 'orange-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.RED]: 'red-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.NEUTRALS]: 'neutrals-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.MIDNIGHT]: 'neutrals-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.PEACH]: 'red-theme',
    [COLOUR_NEW_TO_LEGACY_MAPPING.LEMONADE]: 'yellow-theme',
};

export const THEME_MODES: Record<string, string> = {
    LIGHT: 'light',
    DARK: 'dark',
};

export const SOCIAL_MEDIA_NAMES: Record<string, string> = {
    FACEBOOK: 'Facebook',
    INSTAGRAM: 'Instagram',
    TWITTER: 'Twitter',
    YOUTUBE: 'Youtube',
    LINKEDIN: 'Linkedin',
};

export const SOCIAL_MEDIA_LINKS: Record<string, string> = {
    [SOCIAL_MEDIA_NAMES.FACEBOOK]: 'https://www.facebook.com/',
    [SOCIAL_MEDIA_NAMES.INSTAGRAM]: 'https://www.instagram.com/',
    [SOCIAL_MEDIA_NAMES.TWITTER]: 'https://twitter.com/',
    [SOCIAL_MEDIA_NAMES.YOUTUBE]: 'https://www.youtube.com/',
    [SOCIAL_MEDIA_NAMES.LINKEDIN]: 'https://www.linkedin.com/',
};

export const SOCIAL_MEDIA_ICONS: Record<string, string> = {
    [SOCIAL_MEDIA_NAMES.FACEBOOK]: 'fab fa-facebook',
    [SOCIAL_MEDIA_NAMES.INSTAGRAM]: 'fab fa-instagram fa-fw',
    [SOCIAL_MEDIA_NAMES.TWITTER]: 'fab fa-twitter fa-fw',
    [SOCIAL_MEDIA_NAMES.YOUTUBE]: 'fab fa-youtube fa-fw',
    [SOCIAL_MEDIA_NAMES.LINKEDIN]: 'fab fa-linkedin-in fa-fw',
};
export const ORDER_STATUS: Record<string, number> = {
    ALL: -1,
    DUE: 1,
    PAID: 2,
    SHIPPED: 3,
    COMPLETED: 4,
    REFUNDED: 5,
    APP: 6,
    IN_PROGRESS: 7,
    OVERDUE: 8,
    CANCELLED: 0,
};

export const ORDER_STATUS_NAMES: Record<string, string> = {
    [ORDER_STATUS.ALL]: 'All',
    [ORDER_STATUS.DUE]: 'Due',
    [ORDER_STATUS.PAID]: 'Paid',
    [ORDER_STATUS.SHIPPED]: 'Shipped',
    [ORDER_STATUS.COMPLETED]: 'Completed',
    [ORDER_STATUS.REFUNDED]: 'Refunded',
    [ORDER_STATUS.APP]: 'App',
    [ORDER_STATUS.IN_PROGRESS]: 'In Progress',
    [ORDER_STATUS.OVERDUE]: 'Overdue',
    [ORDER_STATUS.CANCELLED]: 'Cancelled',
};

export const ORDER_STATUS_PSEUDO_VALUE: Record<string, number> = {
    [ORDER_STATUS.DUE]: 1,
    [ORDER_STATUS.PAID]: 2,
    [ORDER_STATUS.SHIPPED]: 3,
    [ORDER_STATUS.COMPLETED]: 4,
    [ORDER_STATUS.REFUNDED]: 5,
    [ORDER_STATUS.APP]: 6,
    [ORDER_STATUS.IN_PROGRESS]: 7,
    [ORDER_STATUS.OVERDUE]: 8,
    [ORDER_STATUS.CANCELLED]: 0,
};

export const ORDER_STATUS_REAL_DB_VALUE: Record<string, number> = {
    [ORDER_STATUS.DUE]: 1,
    [ORDER_STATUS.PAID]: 2,
    [ORDER_STATUS.SHIPPED]: 3,
    [ORDER_STATUS.COMPLETED]: 4,
    [ORDER_STATUS.REFUNDED]: 5,
    [ORDER_STATUS.APP]: 6,
    [ORDER_STATUS.IN_PROGRESS]: 7,
    [ORDER_STATUS.OVERDUE]: 1,
    [ORDER_STATUS.CANCELLED]: 0,
};

export const ORDER_STATUS_COLORS: Record<string, string> = {
    [ORDER_STATUS.DUE]: 'yellow',
    [ORDER_STATUS.PAID]: 'green',
    [ORDER_STATUS.SHIPPED]: 'magenta',
    [ORDER_STATUS.COMPLETED]: '',
    [ORDER_STATUS.REFUNDED]: 'orange',
    [ORDER_STATUS.APP]: '',
    [ORDER_STATUS.IN_PROGRESS]: '',
    [ORDER_STATUS.OVERDUE]: 'orange',
    [ORDER_STATUS.CANCELLED]: 'red',
};

export const ORDER_STATUS_BADGE_ICON: Record<string, string> = {
    [ORDER_STATUS.DUE]: 'fas fa-clock',
    [ORDER_STATUS.PAID]: 'fas fa-check',
    [ORDER_STATUS.SHIPPED]: 'fas fa-location-arrow',
    [ORDER_STATUS.COMPLETED]: '',
    [ORDER_STATUS.REFUNDED]: 'fas fa-arrow-alt-left',
    [ORDER_STATUS.APP]: '',
    [ORDER_STATUS.IN_PROGRESS]: '',
    [ORDER_STATUS.OVERDUE]: 'fas fa-exclamation',
    [ORDER_STATUS.CANCELLED]: 'fas fa-ban',
};

export const ADDON_TYPE: Record<string, number> = {
    QUANTITY: 1,
    METERED: 2,
    ONE_TIME: 3,
};

export const ADDON_TYPE_NAME: Record<string, string> = {
    [ADDON_TYPE.QUANTITY]: 'Quantity',
    [ADDON_TYPE.METERED]: 'Metered',
    [ADDON_TYPE.ONE_TIME]: 'One-Time',
};

export const ARRANGE_PRODUCTS_BY = [
    {'label': 'Name (A - Z)' },
    {'label': 'Name (Z - A)' },
    {'label': 'Price (low - high)' },
    {'label': 'Price (high - low)' },
    {'label': 'Sale Price (low - high'},
    {'label': 'Sale Price (high - low)'},
    {'label': 'Stock (low - high)'},
    {'label': 'Stock (high - low)'},
    {'label': 'Date Created (ascending)'},
    {'label': 'Date Created (descending)'},
    {'label': 'Date Updated (ascending)'},
    {'label': 'Date Updated (descending)'},
];

export const ADDRESS_VALIDATION_MESSAGE: Record<string, string> = {
    ADDRESS_INVALID: 'Please enter a valid address',
    CITY_INVALID: 'Please enter a valid city',
    ZIP_CODE_INVALID: 'Please enter a valid zip code',
    POSTAL_CODE_INVALID: 'Please enter a valid postal code',
    EMAIL_ADDRESS_INVALID: 'Please enter a valid email address',
    PHONE_NUMBER_INVALID: 'Please enter a valid phone number',
};

export const ADDRESS_REQUIRED_MESSAGE: Record<string, string> = {
    ADDRESS_REQUIRED: 'Street Address required',
    CITY_REQUIRED: 'City required',
    POSTAL_CODE_REQUIRED: 'Postal Code required',
    ZIP_CODE_REQUIRED: 'Zip Code required',
    PROVINCE_NAME_REQUIRED: 'Province name required',
    COUNTRY_NAME_REQUIRED: 'Country name required',
};

export const CUSTOMER_VALIDATION_MESSAGE: Record<string, string> = {
    FIRST_NAME_INVALID: 'Please enter a valid first name',
    LAST_NAME_INVALID: 'Please enter a valid last name',
    FULL_NAME_INVALID: 'Please enter a valid full name',
    EMAIL_ADDRESS_INVALID: 'Please enter a valid email address',
    PHONE_NUMBER_INVALID: 'Please enter a valid phone number',
};

export const CUSTOMER_REQUIRED_MESSAGE: Record<string, string> = {
    FULL_NAME_REQUIRED: 'Full name required',
    FIRST_NAME_REQUIRED: 'First name required',
    LAST_NAME_REQUIRED: 'Last name required',
    EMAIL_ADDRESS_REQUIRED: 'Email address required',
    PHONE_NUMBER_REQUIRED: 'Phone number required',
};

export const DAYS_OF_WEEKS: Record<string, number> = {
    MONDAY: 0,
    TUESDAY: 1,
    WEDNESDAY: 2,
    THURSDAY: 3,
    FRIDAY: 4,
    SATURDAY: 5,
    SUNDAY: 6,
};

export const DAYS_OF_WEEKS_NAMES: Record<string, string> = {
    [DAYS_OF_WEEKS.MONDAY]: 'Monday',
    [DAYS_OF_WEEKS.TUESDAY]: 'Tuesday',
    [DAYS_OF_WEEKS.WEDNESDAY]: 'Wednesday',
    [DAYS_OF_WEEKS.THURSDAY]: 'Thursday',
    [DAYS_OF_WEEKS.FRIDAY]: 'Friday',
    [DAYS_OF_WEEKS.SATURDAY]: 'Saturday',
    [DAYS_OF_WEEKS.SUNDAY]: 'Sunday',
};

export const UNIT_WEIGHTS: Record<string, number> = {
    POUND: 1,
    KILOGRAM: 2,
    GRAM: 3,
    OUNCE: 4,
};

export const UNIT_DIMENSIONS: Record<string, number> = {
    INCH: 1,
    METER: 2,
    CENTIMETER: 3,
    MILLIMETER: 4,
    YARD: 5,
};

export const UNIT_WEIGHT_NAMES: Record<string, string> = {
    [UNIT_WEIGHTS.POUND]: 'lbs',
    [UNIT_WEIGHTS.KILOGRAM]: 'kg',
    [UNIT_WEIGHTS.GRAM]: 'g',
    [UNIT_WEIGHTS.OUNCE]: 'oz',
};

export const UNIT_DIMENSIONS_NAMES: Record<string, string> = {
    [UNIT_DIMENSIONS.INCH]: 'in',
    [UNIT_DIMENSIONS.METER]: 'm',
    [UNIT_DIMENSIONS.CENTIMETER]: 'cm',
    [UNIT_DIMENSIONS.MILLIMETER]: 'mm',
    [UNIT_DIMENSIONS.YARD]: 'yd',
};

export enum PAYMENT_METHOD {
    CARD,
    GOOGLE_PAY,
}
