export const CURRENCY = {
    CAD: 1,
    USD: 2,
} as const;

export const CURRENCY_SYMBOL = {
    [CURRENCY.CAD]: '$',
    [CURRENCY.USD]: '$',
};

export const CURRENCY_CODE = {
    [CURRENCY.CAD]: 'CAD',
    [CURRENCY.USD]: 'USD',
} as const;

export const CURRENCY_ABBV = {
    [CURRENCY_CODE[CURRENCY.CAD]]: 1,
    [CURRENCY_CODE[CURRENCY.USD]]: 2,
};

